import React from "react";
import ReactApexChart from "react-apexcharts";

function AllProductColumnChart({ productData, productCount }) {
  class ApexChart extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        series: [
          {
            name: "Products",
            data: productCount, // The values for each product category
          },
        ],
        options: {
          chart: {
            type: "bar",
            animations: {
              enabled: true,
              easing: "easeinout",
              speed: 1500,
              animateGradually: {
                enabled: true,
                delay: 500,
              },
              dynamicAnimation: {
                enabled: true,
                speed: 1000,
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: false, // Keep it vertical
              columnWidth: "60%",
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false, // Disable data labels for simplicity
          },
          markers: {
            size: 6, // Bullet size
            colors: ["#F44336"], // Bullet color
            strokeColors: "#fff",
            strokeWidth: 2,
            hover: {
              sizeOffset: 4,
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["#fff"],
          },
          xaxis: {
            categories: productData, // The product categories for the X-axis
          },
          yaxis: {
            title: {
              text: "Product Count",
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return `${val} products`;
              },
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      };
    }

    render() {
      return (
        <div>
          <div id="chart">
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              height={350}
            />
          </div>
          <div id="html-dist"></div>
        </div>
      );
    }
  }

  return <ApexChart />;
}

export default AllProductColumnChart;
