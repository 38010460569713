import React from "react";
import ReactApexChart from "react-apexcharts";

function SellerProductMovingBulletsChart({ productData, productCount }) {
  class ApexChart extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        series: [
          {
            name: "Product Count",
            data: productCount,
          },
        ],
        options: {
          chart: {
            type: "bar",
            animations: {
              enabled: true,
              easing: "easeinout",
              speed: 800,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              endingShape: "rounded",
            },
          },
          markers: {
            size: 5,
            animation: {
              enabled: true,
              speed: 1000,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: productData,
          },
          yaxis: {
            title: {
              text: "Product Count",
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " products";
              },
            },
          },
        },
      };
    }

    render() {
      return (
        <div>
          <div id="chart">
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              height={350}
            />
          </div>
        </div>
      );
    }
  }

  return <ApexChart />;
}

export default SellerProductMovingBulletsChart;
