import React from "react";
import Chart from "react-apexcharts";

function MonthlySellChart({ months, sale }) {
  const options = {
    chart: {
      id: "apexchart-example",
    },
    xaxis: {
      categories: months || [],
    },
  };

  const series = [
    {
      data: sale || [],
    },
  ];

  return (
    <Chart
      tit
      options={options}
      series={series}
      type="bar"
      // width={500}
      // height={320}
    />
  );
}

export default MonthlySellChart;
