import React from "react";
import ReactApexChart from "react-apexcharts";

function ProductPieChart({ productData, productCount }) {
  class ApexChart extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        series: productCount,
        options: {
          chart: {
            type: "pie",
          },
          labels: productData,
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      };
    }

    render() {
      return (
        <div>
          <div id="chart">
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="pie"
            />
          </div>
          <div id="html-dist"></div>
        </div>
      );
    }
  }

  return <ApexChart />;
}

export default ProductPieChart;
