import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Product.css";

import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import {
  Row,
  Col,
  Form,
  Modal,
  Container,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data, getDimension } = useContext(Context);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [childSubCategories, setChildSubCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [taxTypes, setTaxTypes] = useState([]);
  const [taxPercentages, setPercentages] = useState([]);
  const [attributes, setAttribute] = useState([]);
  const [subAttributes, setSubAttribute] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();

      DataToSend.append("name", data?.name);
      DataToSend.append("short_description", data?.short_description);
      DataToSend.append("manufacturer", data?.manufacturer);
      DataToSend.append("vender", data?.vender);
      DataToSend.append("about", data?.about);
      DataToSend.append("benifits", data?.benifits);
      DataToSend.append("storage", data?.storage);
      DataToSend.append("image1", data?.image1[0]);
      DataToSend.append("category_id", data?.category_id?.value);
      DataToSend.append("sub_category_id", data?.sub_category_id?.value);
      // DataToSend.append(
      //   "child_sub_category_id",
      //   data?.child_sub_category_id?.value
      // );
      DataToSend.append("brand_id", data?.brand_id?.value);
      DataToSend.append("tax_type_id", data?.tax_type_id?.value);
      DataToSend.append("tax_percentage_id", data?.tax_percentage_id?.value);
      DataToSend.append("type", data?.type?.value);

      const main_m_variants = [];

      data.main_m_variants.forEach((mainVariant, mainIndex) => {
        const m_variants = [];

        mainVariant.m_variants.forEach((variant, variantIndex) => {
          const variantData = {
            varient_sub_name: variant?.varient_sub_name,
            unit: variant?.unit,
            mrp: variant?.mrp,
            sku: variant?.sku,
            stock_limit: variant?.stock_limit,
            s_price: variant?.s_price,
            trade: variant?.trade,
            scheme: variant?.scheme,
            discount: variant?.discount,
            images: [],
          };

          for (let i = 0; variant?.image[i]; i++) {
            DataToSend.append(
              `images${mainIndex}${variantIndex}${i}`,
              variant?.image[i]
            );
            variantData.images.push(variant?.image[i]?.name);
          }

          m_variants.push(variantData);
        });

        const mainVariantData = {
          varient_name: mainVariant.varient_name,
          m_variants: m_variants,
        };

        main_m_variants.push(mainVariantData);
      });

      DataToSend.append("main_m_variants", JSON.stringify(main_m_variants));

      const response = await postData(`/product`, DataToSend);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllCategory = async () => {
    const response = await getData("/allcategories");

    if (response?.success) {
      setCategories(await Select2Data(response?.data, "category_id"));
    }
  };

  const GetAllSubCategory = async (id) => {
    const response = await getData(`/allsubcategories/${id}`);

    if (response?.success) {
      setSubCategories(await Select2Data(response?.data, "sub_category_id"));
    }
  };

  const GetAllChildSubCategory = async (id) => {
    const response = await getData(`/allchildsubcategories/${id}`);

    if (response?.success) {
      setChildSubCategories(
        await Select2Data(response?.data, "sub_category_id")
      );
    }
  };

  const GetAllBrands = async () => {
    const response = await getData("/allbrands");

    if (response?.success) {
      setBrands(await Select2Data(response?.data, "brand_id"));
    }
  };

  const GetAllTaxTypes = async () => {
    const response = await getData("/all-tax-type");

    if (response?.success) {
      setTaxTypes(await Select2Data(response?.data, "tax_type_id"));
    }
  };

  const GetAllTaxPercentages = async (id) => {
    const response = await getData(`/all-tax-percentage/${id}`);

    if (response?.success) {
      setPercentages(await Select2Data(response?.data, "tax_percentage_id"));
    }
  };

  const GetAllAttributes = async () => {
    const response = await getData(`/all-attribute`);

    if (response?.success) {
      setAttribute(await Select2Data(response?.data, "attribute_id"));
    }
  };

  const GetAllSubAttributes = async (id) => {
    const response = await getData(`/all-sub-attribute/${id}`);

    if (response?.success) {
      setSubAttribute(await Select2Data(response?.data, "sub_attribute_id"));
    }
  };

  useEffect(() => {
    GetAllCategory();
    GetAllBrands();
    GetAllTaxTypes();
    GetAllAttributes();
  }, []);

  const type = [
    {
      value: "Single",
      label: "Single",
    },
    {
      value: "Multiple",
      label: "Multiple",
    },
  ];

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      category_id: "",
      type: {
        value: "Single",
        label: "Single",
      },
      main_m_variants: [
        {
          varient_name: "",
          m_variants: [
            {
              varient_sub_name: "",
              unit: "",
              mrp: "",
              sku: "",
              s_price: "",
              images: [],
            },
          ],
        },
      ],
    },
  });

  const imageFile = watch("image1");
  const {
    fields: groupFields,
    append: appendGroup,
    remove: removeGroup,
  } = useFieldArray({
    control,
    name: "main_m_variants",
  });

  const handleAddGroup = () => {
    appendGroup({
      varient_name: "",
      m_variants: [
        {
          varient_sub_name: "",
          unit: "",
          mrp: "",
          sku: "",
          s_price: "",
          images: [],
        },
      ],
    });
  };

  return (
    <>
      <div className="prodcuttttt">
        <Offcanvas
          show={props.show}
          style={{ width: "80%" }}
          placement={"end"}
          onHide={props.handleClose}
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>Add Employee</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>Add Product</Card.Title>
                <hr />
                <Container>
                  <Form>
                    <Row>
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Category</Form.Label>
                            <Controller
                              name="category_id"
                              {...register("category_id", {
                                required: "Select Category",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.category_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={categories}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value);
                                    GetAllSubCategory(selectedOption.value);
                                    setValue("category_id", selectedOption);
                                    setValue("sub_category_id", "");
                                    setValue("child_sub_category_id", "");
                                  }}
                                />
                              )}
                            />
                            {errors.category_id && (
                              <span className="text-danger">
                                {errors.category_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Sub Category</Form.Label>
                            <Controller
                              name="sub_category_id"
                              {...register("sub_category_id", {
                                required: "Select Sub Category",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.sub_category_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={subCategories}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value);
                                    GetAllChildSubCategory(
                                      selectedOption.value
                                    );
                                    setValue("sub_category_id", selectedOption);
                                    setValue("child_sub_category_id", "");
                                  }}
                                />
                              )}
                            />
                            {errors.sub_category_id && (
                              <span className="text-danger">
                                {errors.sub_category_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>
                      {/* <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Child Sub Category</Form.Label>
                            <Controller
                              name="child_sub_category_id"
                              {...register("child_sub_category_id", {
                                required: "Select Child Sub Category",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.child_sub_category_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={childSubCategories}
                                />
                              )}
                            />
                            {errors.child_sub_category_id && (
                              <span className="text-danger">
                                {errors.child_sub_category_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col> */}

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Name</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Name .."
                                  className={classNames("", {
                                    "is-invalid": errors?.name,
                                  })}
                                  {...register("name", {
                                    required: "Name is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.name && (
                                <span className="text-danger">
                                  {errors.name.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Short Description</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="short_description"
                                  placeholder="Short Description"
                                  maxLength={40}
                                  className={classNames("", {
                                    "is-invalid": errors?.short_description,
                                  })}
                                  {...register("short_description", {
                                    required: "Short Description is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.short_description && (
                                <span className="text-danger">
                                  {errors.short_description.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Form.Label>Image</Form.Label>

                          <Form.Group>
                            <Form.Control
                              className={classNames("", {
                                "is-invalid": errors?.image1,
                              })}
                              type="file"
                              {...register("image1", {
                                required: "images is required",
                              })}
                              accept="image/*"
                            />
                          </Form.Group>
                          {errors.image1 && (
                            <span className="text-danger">
                              {errors.image1.message}
                            </span>
                          )}
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Form.Label>Image</Form.Label>
                          {imageFile && imageFile?.length > 0 && (
                            <div className="image-preview-container">
                              <img
                                src={URL?.createObjectURL(imageFile[0])}
                                alt="Preview"
                                className="image-preview"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Varient Type</Form.Label>
                            <Controller
                              name="type"
                              control={control}
                              rules={{ required: "Select Brand" }}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.type
                                        ? "red"
                                        : baseStyles.borderColor,
                                    }),
                                  }}
                                  {...field}
                                  options={type}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value);
                                    setValue("type", selectedOption);
                                    reset(getValues());
                                  }}
                                />
                              )}
                            />
                            {errors.type && (
                              <span className="text-danger">
                                {errors.type.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <div className="main-form-section mt-5"></div>
                      <Card.Title>Product Details</Card.Title>
                      <hr />
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Manufacturer</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="manufacturer"
                                  placeholder="Manufacturer"
                                  className={classNames("", {
                                    "is-invalid": errors?.manufacturer,
                                  })}
                                  {...register("manufacturer", {
                                    required: "Manufacturer is Required",
                                  })}
                                />
                              </InputGroup>
                              {errors.manufacturer && (
                                <span className="text-danger">
                                  {errors.manufacturer.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>

                          <Row className="justify-content-center">
                            <Form.Label>Vendor</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="vender"
                                  placeholder="Vendor"
                                  className={classNames("", {
                                    "is-invalid": errors?.vender,
                                  })}
                                  {...register("vender", {
                                    required: "Vender is Required",
                                  })}
                                />
                              </InputGroup>
                              {errors.vender && (
                                <span className="text-danger">
                                  {errors.vender.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Brand</Form.Label>
                            <Controller
                              name="brand_id"
                              {...register("brand_id", {
                                required: "Select Brand",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.brand_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={brands}
                                />
                              )}
                            />
                            {errors.brand_id && (
                              <span className="text-danger">
                                {errors.brand_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Tax Type</Form.Label>
                            <Controller
                              name="tax_type_id"
                              {...register("tax_type_id", {
                                required: "Select Tax Type",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.tax_type_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={taxTypes}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value);
                                    GetAllTaxPercentages(selectedOption.value);
                                    setValue("tax_type_id", selectedOption);
                                  }}
                                />
                              )}
                            />
                            {errors.tax_type_id && (
                              <span className="text-danger">
                                {errors.tax_type_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Tax Percentage</Form.Label>
                            <Controller
                              name="tax_percentage_id"
                              {...register("tax_percentage_id", {
                                required: "Select Tax Percentage",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.tax_percentage_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={taxPercentages}
                                />
                              )}
                            />
                            {errors.tax_percentage_id && (
                              <span className="text-danger">
                                {errors.tax_percentage_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-start">
                            {" "}
                            {/* Align to left */}
                            {/* Description */}
                            <Col sm={12}>
                              <Form.Label className="text-center">
                                About
                              </Form.Label>
                              <Form.Group>
                                <Controller
                                  name="about"
                                  control={control}
                                  rules={{ required: "About is Required" }}
                                  render={({ field }) => (
                                    <JoditEditor
                                      value={field.value}
                                      onChange={(newContent) =>
                                        field.onChange(newContent)
                                      }
                                      onBlur={field.onBlur}
                                      className={classNames("", {
                                        "is-invalid": !!errors.about,
                                      })}
                                      placeholder="About"
                                    />
                                  )}
                                />
                              </Form.Group>
                              {errors.about && (
                                <span className="text-danger">
                                  {errors.about.message}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-start">
                            <Col sm={12}>
                              <Form.Label className="text-center">
                                Benifits
                              </Form.Label>
                              <Form.Group>
                                <Controller
                                  name="benifits"
                                  control={control}
                                  rules={{ required: "Benifits is Required" }}
                                  render={({ field }) => (
                                    <JoditEditor
                                      value={field.value}
                                      onChange={(newContent) =>
                                        field.onChange(newContent)
                                      }
                                      onBlur={field.onBlur}
                                      className={classNames("", {
                                        "is-invalid": !!errors.benifits,
                                      })}
                                      placeholder="Benifits"
                                    />
                                  )}
                                />
                              </Form.Group>
                              {errors.benifits && (
                                <span className="text-danger">
                                  {errors.benifits.message}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-start">
                            <Col sm={12}>
                              <Form.Label className="text-center">
                                Storage
                              </Form.Label>
                              <Form.Group>
                                <Controller
                                  name="storage"
                                  control={control}
                                  rules={{ required: "Storage is required" }}
                                  render={({ field }) => (
                                    <JoditEditor
                                      value={field.value}
                                      onChange={(newContent) =>
                                        field.onChange(newContent)
                                      }
                                      onBlur={field.onBlur}
                                      className={classNames("", {
                                        "is-invalid": !!errors.storage,
                                      })}
                                      placeholder="Storage"
                                    />
                                  )}
                                />
                              </Form.Group>
                              {errors.storage && (
                                <span className="text-danger">
                                  {errors.storage.message}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <div className="main-form-section mt-5"></div>
                      <Card.Title>
                        Product Variants Details
                        {/* {variantsError && (
                          <div className="text-danger">{variantsError}</div>
                        )} */}
                      </Card.Title>
                      <hr />
                      {groupFields.map((variant, groupIndex) => (
                        <>
                          <div
                            key={groupIndex}
                            className="main-form-section mt-3"
                          >
                            <Row>
                              {getValues("type")?.value == "Single" ? (
                                ""
                              ) : (
                                <Col md={3}>
                                  <div className="main-form-section mt-3">
                                    <Row className="justify-content-center">
                                      <Form.Label>Variant Name</Form.Label>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name={`main_m_variants.${groupIndex}.varient_name`}
                                            placeholder="Variant Name"
                                            className={classNames("", {
                                              "is-invalid":
                                                errors?.main_m_variants?.[
                                                  groupIndex
                                                ]?.varient_name,
                                            })}
                                            {...register(
                                              `main_m_variants.${groupIndex}.varient_name`,
                                              {
                                                required:
                                                  "Variant name is required",
                                              }
                                            )}
                                          />
                                        </InputGroup>
                                        {errors?.main_m_variants?.[groupIndex]
                                          ?.varient_name && (
                                          <span className="text-danger">
                                            {
                                              errors.main_m_variants[groupIndex]
                                                .varient_name.message
                                            }
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Row>
                                  </div>
                                </Col>
                              )}
                              <FieldArray
                                control={control}
                                groupIndex={groupIndex}
                                register={register}
                                errors={errors}
                                getValues={getValues}
                                watch={watch}
                                setValue={setValue}
                              />

                              {getValues("type")?.value == "Single" ? (
                                ""
                              ) : (
                                <Col md={3}>
                                  <button
                                    type="button"
                                    className="mt-3 add-varient"
                                    onClick={() => removeGroup(groupIndex)}
                                  >
                                    Remove Group
                                  </button>
                                </Col>
                              )}
                            </Row>
                          </div>
                        </>
                      ))}
                      {getValues("type")?.value == "Single" ? (
                        ""
                      ) : (
                        <button
                          type="button"
                          className="mt-3 add-varient"
                          onClick={() => handleAddGroup()}
                        >
                          Add Group
                        </button>
                      )}
                    </Row>
                    <Row className="mt-5 pb-3">
                      <div className="d-flex justify-content-center">
                        <Link>
                          <CancelButton
                            name={"cancel"}
                            handleClose={props.handleClose}
                          />
                        </Link>

                        <Button
                          name={"save"}
                          onClick={handleSubmit(onSubmit)}
                          type="button"
                          className="save-btnnnnnn"
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-floppy-disk"
                            className="me-2"
                          />
                          save
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </Container>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Offcanvas>

        <ModalSave
          message={showModal.message}
          showErrorModal={showModal.code ? true : false}
        />
      </div>
    </>
  );
};

const FieldArray = ({
  control,
  groupIndex,
  register,
  errors,
  getValues,
  setValue,
  watch,
}) => {
  const {
    fields: itemFields,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: `main_m_variants.${groupIndex}.m_variants`,
  });

  const handleAddItem = () => {
    appendItem({ value: "" });
  };

  const handleMRPChange = (itemIndex, newMRP) => {
    const trade = parseFloat(
      getValues(
        `main_m_variants.${groupIndex}.m_variants.${itemIndex}.trade`
      ) || 0
    );

    const scheme = parseFloat(
      getValues(
        `main_m_variants.${groupIndex}.m_variants.${itemIndex}.scheme`
      ) || 0
    );
    const discount = parseFloat(
      getValues(
        `main_m_variants.${groupIndex}.m_variants.${itemIndex}.discount`
      ) || 0
    );

    // Calculate the scheme price as MRP - Scheme Percent
    const TradePrice = newMRP / trade;
    const schemePrice = TradePrice - TradePrice * (scheme / 100);
    const sellingPrice = schemePrice - discount;

    // Update the scheme price and selling price in the form

    setValue(
      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.trade_price`,
      TradePrice.toFixed(2)
    );

    setValue(
      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.scheme_price`,
      schemePrice.toFixed(2)
    );

    setValue(
      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.s_price`,
      sellingPrice.toFixed(2)
    );
  };

  // Watch the fields
  const watchItems = watch(
    `main_m_variants.${groupIndex}.m_variants`,
    itemFields
  );

  useEffect(() => {
    watchItems.forEach((item, itemIndex) => {
      const mrp = parseFloat(item.mrp || 0);
      handleMRPChange(itemIndex, mrp);
    });
  }, [watchItems, groupIndex, setValue]);

  return (
    <>
      {itemFields.map((item, itemIndex) => (
        <div key={item.id} className="main-form-section mt-3">
          <Row>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>
                    {" "}
                    {getValues("type")?.value == "Single" ? "" : "Sub"} Variant
                    Name
                  </Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.varient_sub_name`}
                        placeholder={
                          (getValues("type")?.value == "Single" ? "" : "Sub ") +
                          "Variant Name"
                        }
                        className={classNames("", {
                          "is-invalid":
                            errors.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.varient_sub_name,
                        })}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.varient_sub_name`,
                          {
                            required: "Sub Variant name is required",
                          }
                        )}
                      />
                    </InputGroup>
                    {errors.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.varient_sub_name && (
                      <span className="text-danger">
                        {
                          errors.variants?.[groupIndex]?.m_variants?.[itemIndex]
                            ?.varient_sub_name?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Stock Unit</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.unit`}
                        placeholder="Stock Unit"
                        className={classNames("", {
                          "is-invalid":
                            errors.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.unit,
                        })}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.unit`,
                          {
                            required: "Stock Unit is required",
                            validate: (value) =>
                              value >= 0 || "Stock Unit cannot be negative",
                          }
                        )}
                      />
                    </InputGroup>
                    {errors.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.unit && (
                      <span className="text-danger">
                        {
                          errors.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.unit.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>

            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>MRP</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        placeholder="MRP"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.mrp,
                        })}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`,
                          {
                            required: "MRP is required",
                            validate: (value) =>
                              value >= 0 || "MRP cannot be negative",
                            onChange: (e) => {
                              const newMRP = parseFloat(e.target.value || 0);
                              handleMRPChange(itemIndex, newMRP);
                            },
                          }
                        )}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.mrp && (
                      <span className="text-danger">
                        {
                          errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.mrp?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>

            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Trade Percent</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        placeholder="Trade Percent"
                        max={100}
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.trade,
                        })}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.trade`,
                          {
                            required: "Trade Percent is required",

                            validate: {
                              minValue: (value) =>
                                value >= 0 ||
                                "Trade Percent cannot be Negative",
                              maxValue: (value) =>
                                value <= 100 ||
                                "Trade Percent cannot exceed 100",
                            },

                            onChange: (e) => {
                              const newScheme = parseFloat(e.target.value || 0);
                              const mrp = parseFloat(
                                getValues(
                                  `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`
                                ) || 0
                              );
                              handleMRPChange(itemIndex, mrp);
                            },
                          }
                        )}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.trade && (
                      <span className="text-danger">
                        {
                          errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.trade?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>

            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Scheme Percent</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        placeholder="Scheme Percent"
                        max={100}
                        defaultValue={0}
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.scheme,
                        })}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.scheme`,
                          {
                            required: "Scheme Percent is required",
                            validate: (value) =>
                              value >= 0 || "Scheme Percent cannot be negative",
                            validate: {
                              minValue: (value) =>
                                value >= 0 ||
                                "Scheme Percent cannot be Negative",
                              maxValue: (value) =>
                                value <= 100 ||
                                "Scheme Percent cannot exceed 100",
                            },
                            onChange: (e) => {
                              const newScheme = parseFloat(e.target.value || 0);
                              const mrp = parseFloat(
                                getValues(
                                  `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`
                                ) || 0
                              );
                              handleMRPChange(itemIndex, mrp);
                            },
                          }
                        )}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.scheme && (
                      <span className="text-danger">
                        {
                          errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.scheme?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>

            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Discount In Rupees </Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.discount`}
                        placeholder="Discount Percent"
                        defaultValue={0}
                        max={100}
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.discount,
                        })}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.discount`,
                          {
                            required: "Discount Rupees is required",
                            validate: (value) =>
                              value >= 0 ||
                              "Discount Rupees cannot be negative",
                            // validate: {
                            //   minValue: (value) =>
                            //     value >= 0 ||
                            //     "Discount Percent cannot be Negative",
                            //   maxValue: (value) =>
                            //     value <= 100 ||
                            //     "Discount Percent cannot exceed 100",
                            // },
                            onChange: (e) => {
                              const newDiscount = parseFloat(
                                e.target.value || 0
                              );
                              const mrp = parseFloat(
                                getValues(
                                  `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`
                                ) || 0
                              );
                              handleMRPChange(itemIndex, mrp);
                            },
                          }
                        )}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.discount && (
                      <span className="text-danger">
                        {
                          errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.discount?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>

            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Trade Price</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        placeholder="Trade Price"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.trade_price,
                        })}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.trade_price`,
                          {
                            required: "Trade Price is required",
                            validate: (value) =>
                              value >= 0 || "Trade Price cannot be negative",
                            validate: (value) => {
                              return true;
                            },
                          }
                        )}
                        readOnly
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.trade_price && (
                      <span className="text-danger">
                        {
                          errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.trade_price?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>

            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Scheme Price</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        placeholder="Selling Price"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.s_price,
                        })}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.scheme_price`,
                          {
                            required: "Scheme Price is required",

                            validate: (value) => {
                              return true;
                            },
                          }
                        )}
                        readOnly
                      />
                    </InputGroup>
                    {/* {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.s_price && (
                      <span className="text-danger">
                        {
                          errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.s_price?.message
                        }
                      </span>
                    )} */}
                  </Form.Group>
                </Row>
              </div>
            </Col>

            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Selling Price</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.s_price`}
                        placeholder="Selling Price"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.s_price,
                        })}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.s_price`,
                          {
                            required: "Selling Price is required",
                            validate: (value) => {
                              if (!value) return "Selling Price is required";
                              const minimumOrderValue = parseFloat(
                                getValues(
                                  `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`
                                )
                              );
                              if (parseFloat(value) <= 0) {
                                return "Selling Price must be a positive number";
                              }

                              // Check if discount is less than minimum order value
                              if (parseFloat(value) > minimumOrderValue) {
                                return "Selling Price must not be greater than MRP";
                              }

                              return true; // Return true for valid input
                            },
                          }
                        )}
                      />
                    </InputGroup>
                    {errors.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.s_price && (
                      <span className="text-danger">
                        {
                          errors.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.s_price.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>

            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>SKU</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.sku`}
                        placeholder="sku"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.sku,
                        })}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.sku`,
                          {
                            required: "SKU is required",
                          }
                        )}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.sku && (
                      <span className="text-danger">
                        {
                          errors.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.sku.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>

            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Stock limit</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.stock_limit`}
                        placeholder="stock limit"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.stock_limit,
                        })}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.stock_limit`,
                          {
                            required: "Stock Limit is required",
                            validate: (value) =>
                              value >= 0 || "Stock Limit cannot be negative",
                          }
                        )}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.stock_limit && (
                      <span className="text-danger">
                        {
                          errors.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.stock_limit.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>

            <Col lg={3}>
              <div className="main-form-section mt-3">
                <Form.Label>images</Form.Label>

                <Form.Group>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid":
                        errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                          itemIndex
                        ]?.image,
                    })}
                    type="file"
                    multiple
                    {...register(
                      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.image`,
                      {
                        required: "images is required",
                      }
                    )}
                    accept="image/*"
                  />
                </Form.Group>
                {errors?.main_m_variants?.[groupIndex]?.m_variants?.[itemIndex]
                  ?.image && (
                  <span className="text-danger">
                    {
                      errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                        itemIndex
                      ]?.image.message
                    }
                  </span>
                )}
              </div>
            </Col>
            <Col lg={3}>
              <div className="main-form-section mt-3">
                {/* <Form.Label></Form.Label> */}

                <Form.Group>
                  <button
                    type="button"
                    className="mt-3 add-varient"
                    onClick={() => removeItem(itemIndex)}
                  >
                    Remove Item
                  </button>
                </Form.Group>
              </div>
            </Col>
            {/* <label>
            Item Value:
            <input
              {...register(
                `variants.${groupIndex}.m_variants.${itemIndex}.value`
              )}
            />
          </label> */}
          </Row>
        </div>
      ))}
      <Col md={3}>
        <button
          type="button"
          className="mt-3 add-varient"
          onClick={handleAddItem}
        >
          Add Item
        </button>
      </Col>
    </>
  );
};

export default AddOffCanvance;
