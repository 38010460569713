// import React, { useContext } from "react";
// import { useState, useEffect } from "react";
// import "../../Tabels/Tabels.css";
// import { Link } from "react-router-dom";

// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fas } from "@fortawesome/free-solid-svg-icons";

// import Offcanvas from "react-bootstrap/Offcanvas";
// import Card from "react-bootstrap/Card";
// import ModalSave from "../../common/ModelSave";
// import Select from "react-select";
// import { CancelButton, SaveButton } from "../../common/Button";
// import {
//   Row,
//   Col,
//   Form,
//   Modal,
//   Container,
//   InputGroup,
//   Button,
// } from "react-bootstrap";
// import { useForm, Controller, useFieldArray } from "react-hook-form";
// import classNames from "classnames";
// import { Context } from "../../../utils/context";
// import JoditEditor from "jodit-react";
// import Table from "react-bootstrap/Table";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// library.add(fas);

// const EditOffCanvance = (props) => {
//   const {
//     postData,
//     getData,
//     Select2Data,
//     IMG_URL,
//     getDimension,
//     deleteData,
//     user,
//   } = useContext(Context);

//   const [categories, setCategories] = useState([]);
//   const [subCategories, setSubCategories] = useState([]);
//   const [childSubCategories, setChildSubCategories] = useState([]);
//   const [brands, setBrands] = useState([]);
//   const [taxTypes, setTaxTypes] = useState([]);
//   const [taxPercentages, setPercentages] = useState([]);
//   const [attributes, setAttribute] = useState([]);
//   const [subAttributes, setSubAttribute] = useState([]);
//   const id = props.show;
//   const [role, setRole] = useState([]);
//   const [data, setData] = useState();
//   const [isDisabled, setIsDisabled] = useState(false);

//   useEffect(() => {
//     console.log("i am here ", user);
//     if (parseInt(user?.id) === 1) {
//       setIsDisabled(true);
//     }
//   }, [user]);

//   console.log("isDisabled ", isDisabled);

//   const [showModal, setShowModal] = useState({
//     code: 0,
//     message: "",
//   });

//   const {
//     control,
//     register,
//     handleSubmit,
//     getValues,
//     formState: { errors },
//     watch,
//     setValue,
//     reset,
//   } = useForm();
//   const imagesFile = watch("images");
//   const imageFile = watch("image1");
//   // console.log("imagesFile:", imagesFile);

//   const { fields, append, remove } = useFieldArray({
//     control,
//     name: "variants", // Name of your field array
//   });

//   console.log(fields, "fields");

//   const {
//     fields: m_fields,
//     m_append,
//     m_remove,
//   } = useFieldArray({
//     control,
//     name: "m_main_variants", // Name of your field array
//   });

//   console.log(m_fields, "m_fields");

//   const [variantsError, setVariantsError] = useState("");

//   useEffect(() => {
//     register("variants", {
//       validate: (value) => {
//         const isValid = value && value.length > 0;
//         setVariantsError(isValid ? "" : "At least one variant is required");
//         return isValid;
//       },
//     });
//   }, [register]);

//   const validateSellingPrice = (value, index) => {
//     const mrpValue = parseFloat(getValues(`variants.${index}.mrp`));
//     const sellingPrice = parseFloat(value);
//     if (sellingPrice >= mrpValue) {
//       return "Selling price must be less than MRP";
//     }
//     return true; // Validation passed
//   };

//   // const onSubmit = async (data) => {
//   //   try {
//   //     const DataToSend = new FormData();
//   //     DataToSend.append("name", data?.name);
//   //     DataToSend.append("short_description", data?.short_description);
//   //     DataToSend.append("manufacturer", data?.manufacturer);
//   //     DataToSend.append("vender", data?.vender);
//   //     DataToSend.append("about", data?.about);
//   //     DataToSend.append("benifits", data?.benifits);
//   //     DataToSend.append("storage", data?.storage);
//   //     DataToSend.append("image1", data?.image1[0]);
//   //     DataToSend.append("category_id", data?.category_id?.value);
//   //     DataToSend.append("sub_category_id", data?.sub_category_id?.value);
//   //     DataToSend.append("child_sub_category_id", data?.child_sub_category_id?.value);
//   //     DataToSend.append("brand_id", data?.brand_id?.value);
//   //     DataToSend.append("tax_type_id", data?.tax_type_id?.value);
//   //     DataToSend.append("tax_percentage_id", data?.tax_percentage_id?.value);

//   //     // Append variants
//   //     const variants = [];
//   //     data.variants.forEach((variant, index) => {
//   //       variants.push({
//   //         // attribute_id: variant.attribute_id?.value,
//   //         // sub_attribute_id: variant.sub_attribute_id?.value,
//   //         variant_id: variant?.id,
//   //         v_name: variant.v_name,
//   //         unit: variant.unit,
//   //         mrp: variant.mrp,
//   //         sku: variant.sku,
//   //         s_price: variant.s_price,
//   //       });

//   //       let inputImages = document.getElementById(`variantImages${index}`);
//   //       console.log(inputImages.files);
//   //       const files = inputImages.files;
//   //       for (let i = 0; i < files.length; i++) {
//   //         DataToSend.append(`images${index}`, files[i])
//   //       }

//   //     });

//   //     DataToSend.append("variants", JSON.stringify(variants));
//   //     const response = await postData(`/product/${id}`, DataToSend);
//   //     console.log("response", response);
//   //     console.log("data to send", DataToSend);
//   //     if (response?.success) {
//   //       await setShowModal({ code: response.code, message: response.message });
//   //     } else {
//   //       await setShowModal({ code: response.code, message: response.message });
//   //     }
//   //     setTimeout(() => {
//   //       setShowModal(0);
//   //       props.handleClose();
//   //     }, 1000);
//   //   } catch (error) {
//   //     console.log(error);
//   //   }
//   // };

//   const onSubmit = async (data) => {
//     try {
//       const dataToSend = new FormData();
//       dataToSend.append("stage", data?.stage?.value);
//       // dataToSend.append("email", data?.email)
//       const response = await postData(`/product/request/${id}`, dataToSend);
//
//       if (response?.success) {
//         await setShowModal({ code: response.code, message: response.message });
//       } else {
//         await setShowModal({ code: response.code, message: response.message });
//       }
//       setTimeout(() => {
//         setShowModal(0);
//         props.handleClose();
//       }, 1000);
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const deleteImage = async (imageId) => {
//     try {
//       const response = await deleteData(`/product/product-image/${imageId}`);
//       if (response.success) {
//         console.log("Image deleted successfully");
//       } else {
//         console.error("Failed to delete image");
//       }
//       GetEditData();
//     } catch (error) {
//       console.error("Error deleting image:", error);
//     }
//   };
//   const deleteLocalImage = async (index) => {
//     const updatedImagesFile = [...imagesFile];
//     updatedImagesFile.splice(index, 1);
//     setValue("images", updatedImagesFile);
//   };

//   const GetAllCategory = async () => {
//     const response = await getData("/allcategories");

//     if (response?.success) {
//       setCategories(await Select2Data(response?.data, "category_id"));
//     }
//   };

//   const GetAllSubCategory = async (id) => {
//     const response = await getData(`/allsubcategories/${id}`);

//     if (response?.success) {
//       setSubCategories(await Select2Data(response?.data, "sub_category_id"));
//     }
//   };

//   const GetAllChildSubCategory = async (id) => {
//     const response = await getData(`/allchildsubcategories/${id}`);

//     if (response?.success) {
//       setChildSubCategories(
//         await Select2Data(response?.data, "child_sub_category_id")
//       );
//     }
//   };

//   const GetAllBrands = async () => {
//     const response = await getData("/allbrands");

//     if (response?.success) {
//       setBrands(await Select2Data(response?.data, "brand_id"));
//     }
//   };

//   const GetAllTaxTypes = async () => {
//     const response = await getData("/all-tax-type");

//     if (response?.success) {
//       setTaxTypes(await Select2Data(response?.data, "tax_type_id"));
//     }
//   };

//   const GetAllTaxPercentages = async (id) => {
//     const response = await getData(`/all-tax-percentage/${id}`);

//     if (response?.success) {
//       setPercentages(await Select2Data(response?.data, "tax_percentage_id"));
//     }
//   };

//   const GetAllAttributes = async () => {
//     const response = await getData(`/all-attribute`);

//     if (response?.success) {
//       setAttribute(await Select2Data(response?.data, "attribute_id"));
//     }
//   };

//   const GetAllSubAttributes = async (id) => {
//     const response = await getData(`/all-sub-attribute/${id}`);

//     if (response?.success) {
//       setSubAttribute(await Select2Data(response?.data, "sub_attribute_id"));
//     }
//   };

//   console.log(getValues("images[0]"), "llll");

//   const GetEditData = async () => {
//     const response = await getData(`/product/${id}`);
//     setData(response);
//     setData(response?.data);
//     reset(response?.data);
//   };

//   useEffect(() => {
//     GetEditData();
//     GetAllCategory();
//     GetAllBrands();
//     GetAllTaxTypes();
//     GetAllAttributes();
//   }, []);

//   const handleRemoveVariant = async (index, variantId) => {
//     // Remove the variant from the fields array
//     console.log("variantId :-", variantId);
//     remove(index);
//     // try {
//     // const vData = {
//     //   variant_id: variantId,
//     //   product_id: id
//     // }
//     // console.log(vData);
//     // const response = await deleteData(`/product/delete-variant`, { vData });
//     // if (response.success) {
//     //   console.log("Image deleted successfully");
//     // } else {
//     //   console.error("Failed to delete image");
//     // }
//     // GetEditData();
//     // } catch (error) {
//     //   console.error("Error deleting image:", error);
//     // }
//   };

//   return (
//     <>
//       <Offcanvas
//         show={props.show}
//         style={{ width: "80%" }}
//         placement={"end"}
//         onHide={props.handleClose}
//       >
//         <Offcanvas.Header closeButton>
//           {/* <Offcanvas.Title>Edit Employee</Offcanvas.Title> */}
//         </Offcanvas.Header>
//         <Offcanvas.Body>
//           <Card className="shadow-lg p-3 mb-5 bg-white rounded">
//             <Card className="shadow-lg p-3 mb-5 bg-white rounded">
//               <Card.Body>
//                 <Card.Title>Product Request</Card.Title>
//                 <hr />
//                 <Container>
//                   <Form
//                     // onSubmit={() => handleSubmit(onSubmit)}
//                     role="form"
//                     // className="stateclass"
//                   >
//                     <Row>
//                       <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="row justify-content-center mb-2">
//                             <Form.Label>Category</Form.Label>
//                             <Controller
//                               name="category_id" // name of the field
//                               {...register("category_id", {
//                                 required: "Select Category",
//                               })}
//                               control={control}
//                               render={({ field }) => (
//                                 <Select
//                                   isDisabled
//                                   styles={{
//                                     control: (baseStyles) => ({
//                                       ...baseStyles,
//                                       borderColor: errors.category_id
//                                         ? "red"
//                                         : baseStyles,
//                                     }),
//                                   }}
//                                   {...field}
//                                   options={categories}
//                                   onChange={(selectedOption) => {
//                                     field.onChange(selectedOption.value); // Update Controller's value
//                                     GetAllSubCategory(selectedOption.value);
//                                     setValue("category_id", selectedOption);
//                                     setValue("sub_category_id", "");
//                                     setValue("child_sub_category_id", "");
//                                   }}
//                                 />
//                               )}
//                             />
//                             {errors.category_id && (
//                               <span className="text-danger">
//                                 {errors.category_id.message}
//                               </span>
//                             )}
//                           </Row>
//                         </div>
//                       </Col>

//                       <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="row justify-content-center mb-2">
//                             <Form.Label>Sub Category</Form.Label>
//                             <Controller
//                               name="sub_category_id" // name of the field
//                               {...register("sub_category_id", {
//                                 required: "Select Sub Category",
//                               })}
//                               control={control}
//                               render={({ field }) => (
//                                 <Select
//                                   isDisabled
//                                   styles={{
//                                     control: (baseStyles) => ({
//                                       ...baseStyles,
//                                       borderColor: errors.sub_category_id
//                                         ? "red"
//                                         : baseStyles,
//                                     }),
//                                   }}
//                                   {...field}
//                                   options={subCategories}
//                                   onChange={(selectedOption) => {
//                                     field.onChange(selectedOption.value); // Update Controller's value
//                                     GetAllChildSubCategory(
//                                       selectedOption.value
//                                     );
//                                     setValue("sub_category_id", selectedOption);
//                                     setValue("child_sub_category_id", "");
//                                   }}
//                                 />
//                               )}
//                             />
//                             {errors.sub_category_id && (
//                               <span className="text-danger">
//                                 {errors.sub_category_id.message}
//                               </span>
//                             )}
//                           </Row>
//                         </div>
//                       </Col>

//                       <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="row justify-content-center mb-2">
//                             <Form.Label>Child Sub Category</Form.Label>
//                             <Controller
//                               name="child_sub_category_id" // name of the field
//                               {...register("child_sub_category_id", {
//                                 required: "Select Child Sub Category",
//                               })}
//                               control={control}
//                               render={({ field }) => (
//                                 <Select
//                                   isDisabled
//                                   styles={{
//                                     control: (baseStyles) => ({
//                                       ...baseStyles,
//                                       borderColor: errors.child_sub_category_id
//                                         ? "red"
//                                         : baseStyles,
//                                     }),
//                                   }}
//                                   {...field}
//                                   options={childSubCategories}
//                                 />
//                               )}
//                             />
//                             {errors.child_sub_category_id && (
//                               <span className="text-danger">
//                                 {errors.child_sub_category_id.message}
//                               </span>
//                             )}
//                           </Row>
//                         </div>
//                       </Col>

//                       <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="justify-content-center">
//                             <Form.Label>Name</Form.Label>
//                             <Form.Group>
//                               <InputGroup>
//                                 <Form.Control
//                                   disabled
//                                   type="text"
//                                   name="name"
//                                   placeholder="Name .."
//                                   className={classNames("", {
//                                     "is-invalid": errors?.name,
//                                   })}
//                                   {...register("name", {
//                                     required: "Name is required",
//                                   })}
//                                 />
//                               </InputGroup>
//                               {errors.name && (
//                                 <span className="text-danger">
//                                   {errors.name.message}
//                                 </span>
//                               )}
//                             </Form.Group>
//                           </Row>
//                         </div>
//                       </Col>

//                       <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="justify-content-center">
//                             <Form.Label>Short Description</Form.Label>
//                             <Form.Group>
//                               <InputGroup>
//                                 <Form.Control
//                                   disabled
//                                   type="text"
//                                   name="short_description"
//                                   placeholder="Short Description"
//                                   maxLength={40}
//                                   className={classNames("", {
//                                     "is-invalid": errors?.short_description,
//                                   })}
//                                   {...register("short_description", {
//                                     required: "Short Description is required",
//                                   })}
//                                 />
//                               </InputGroup>
//                               {errors.short_description && (
//                                 <span className="text-danger">
//                                   {errors.short_description.message}
//                                 </span>
//                               )}
//                             </Form.Group>
//                           </Row>
//                         </div>
//                       </Col>

//                       {/* <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Form.Label>
//                             image
//                           </Form.Label>

//                           <Form.Group>
//                             <Form.Control
//                               className={classNames("", {
//                                 "is-invalid": errors?.image1,
//                               })}
//                               type="file"
//                               multiple
//                               {...register("image1", {
//                                 // required: "images is required",
//                               })}

//                               accept="image/*"
//                             />
//                           </Form.Group>
//                           {errors.image1 && (
//                             <span className="text-danger">
//                               {errors.image1.message}
//                             </span>
//                           )}

//                         </div>
//                       </Col> */}

//                       <Col lg={3}>
//                         <div className="main-form-section mt-3">
//                           <Form.Label>Product Image</Form.Label>

//                           {typeof getValues("image1") == "string" ? (
//                             <div className="image-preview-container">
//                               <img
//                                 src={IMG_URL + getValues("image1")}
//                                 alt="Preview"
//                                 className="image-preview"
//                                 style={{ width: "150px", height: "130px" }}
//                               />
//                             </div>
//                           ) : (
//                             imageFile &&
//                             imageFile?.length > 0 && (
//                               <div className="image-preview-container">
//                                 <img
//                                   // src={URL.createObjectURL(getValues("image")[0])}
//                                   src={URL?.createObjectURL(imageFile[0])}
//                                   alt="Preview"
//                                   className="image-preview"
//                                   style={{ width: "100px", height: "100px" }}
//                                 />
//                               </div>
//                             )
//                           )}
//                         </div>
//                       </Col>

//                       <div className="main-form-section mt-5"></div>
//                       <Card.Title>Product Details</Card.Title>
//                       <hr />

//                       <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="justify-content-center">
//                             <Form.Label>manufacturer</Form.Label>
//                             <Form.Group>
//                               <InputGroup>
//                                 <Form.Control
//                                   disabled
//                                   type="text"
//                                   name="manufacturer"
//                                   placeholder="manufacturer"
//                                   className={classNames("", {
//                                     "is-invalid": errors?.manufacturer,
//                                   })}
//                                   {...register("manufacturer", {
//                                     required: "manufacturer is required",
//                                   })}
//                                 />
//                               </InputGroup>
//                               {errors.manufacturer && (
//                                 <span className="text-danger">
//                                   {errors.manufacturer.message}
//                                 </span>
//                               )}
//                             </Form.Group>
//                           </Row>

//                           <Row className="justify-content-center">
//                             <Form.Label>vender</Form.Label>
//                             <Form.Group>
//                               <InputGroup>
//                                 <Form.Control
//                                   disabled
//                                   type="text"
//                                   name="vender"
//                                   placeholder="vender"
//                                   className={classNames("", {
//                                     "is-invalid": errors?.vender,
//                                   })}
//                                   {...register("vender", {
//                                     required: "vender is required",
//                                   })}
//                                 />
//                               </InputGroup>
//                               {errors.vender && (
//                                 <span className="text-danger">
//                                   {errors.vender.message}
//                                 </span>
//                               )}
//                             </Form.Group>
//                           </Row>
//                         </div>
//                       </Col>

//                       {/* <Col md={3}>
//   <div className="main-form-section mt-3">
//     <Row className="justify-content-center">
//       <Form.Label>vender</Form.Label>
//       <Form.Group>
//         <InputGroup>
//           <Form.Control
//             type="text"
//             name="vender"
//             placeholder="vender"
//             className={classNames("", {
//               "is-invalid": errors?.vender,
//             })}
//             {...register("vender", {
//               required: "vender is required",
//             })}
//           />
//         </InputGroup>
//         {errors.vender && (
//           <span className="text-danger">
//             {errors.vender.message}
//           </span>
//         )}
//       </Form.Group>
//     </Row>
//   </div>
// </Col> */}

//                       <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="row justify-content-center mb-2">
//                             <Form.Label>Brand</Form.Label>
//                             <Controller
//                               name="brand_id" // name of the field
//                               {...register("brand_id", {
//                                 required: "Select Brand",
//                               })}
//                               control={control}
//                               render={({ field }) => (
//                                 <Select
//                                   isDisabled
//                                   styles={{
//                                     control: (baseStyles) => ({
//                                       ...baseStyles,
//                                       borderColor: errors.brand_id
//                                         ? "red"
//                                         : baseStyles,
//                                     }),
//                                   }}
//                                   {...field}
//                                   options={brands}
//                                 />
//                               )}
//                             />
//                             {errors.brand_id && (
//                               <span className="text-danger">
//                                 {errors.brand_id.message}
//                               </span>
//                             )}
//                           </Row>
//                         </div>
//                       </Col>

//                       <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="row justify-content-center mb-2">
//                             <Form.Label>Tax Type</Form.Label>
//                             <Controller
//                               name="tax_type_id" // name of the field
//                               {...register("tax_type_id", {
//                                 required: "Select Tax Type",
//                               })}
//                               control={control}
//                               render={({ field }) => (
//                                 <Select
//                                   isDisabled
//                                   styles={{
//                                     control: (baseStyles) => ({
//                                       ...baseStyles,
//                                       borderColor: errors.tax_type_id
//                                         ? "red"
//                                         : baseStyles,
//                                     }),
//                                   }}
//                                   {...field}
//                                   options={taxTypes}
//                                   onChange={(selectedOption) => {
//                                     field.onChange(selectedOption.value); // Update Controller's value
//                                     GetAllTaxPercentages(selectedOption.value);
//                                     setValue("tax_type_id", selectedOption);
//                                   }}
//                                 />
//                               )}
//                             />
//                             {errors.tax_type_id && (
//                               <span className="text-danger">
//                                 {errors.tax_type_id.message}
//                               </span>
//                             )}
//                           </Row>
//                         </div>
//                       </Col>

//                       <Col md={3}>
//                         <div className="main-form-section mt-3">
//                           <Row className="row justify-content-center mb-2">
//                             <Form.Label>Tax Percentage</Form.Label>
//                             <Controller
//                               name="tax_percentage_id" // name of the field
//                               {...register("tax_percentage_id", {
//                                 required: "Select Tax Percentage",
//                               })}
//                               control={control}
//                               render={({ field }) => (
//                                 <Select
//                                   isDisabled
//                                   styles={{
//                                     control: (baseStyles) => ({
//                                       ...baseStyles,
//                                       borderColor: errors.tax_percentage_id
//                                         ? "red"
//                                         : baseStyles,
//                                     }),
//                                   }}
//                                   {...field}
//                                   options={taxPercentages}
//                                 />
//                               )}
//                             />
//                             {errors.tax_percentage_id && (
//                               <span className="text-danger">
//                                 {errors.tax_percentage_id.message}
//                               </span>
//                             )}
//                           </Row>
//                         </div>
//                       </Col>

//                       <Col lg={6}>
//                         <div className="main-form-section mt-3">
//                           <Row className="justify-content-start">
//                             {" "}
//                             {/* Align to left */}
//                             {/* Description */}
//                             <Col sm={12}>
//                               <Form.Label className="text-center">
//                                 about
//                               </Form.Label>
//                               <Form.Group>
//                                 <Controller
//                                   name="about"
//                                   control={control}
//                                   rules={{ required: "about is required" }}
//                                   render={({ field }) => (
//                                     <JoditEditor
//                                       value={field.value}
//                                       onChange={(newContent) =>
//                                         field.onChange(newContent)
//                                       }
//                                       onBlur={field.onBlur}
//                                       className={classNames("", {
//                                         "is-invalid": !!errors.about,
//                                       })}
//                                       placeholder="about"
//                                       config={{
//                                         readonly: true, // Set the editor to read-only mode
//                                         toolbar: false, // Hide the toolbar
//                                       }}
//                                     />
//                                   )}
//                                 />
//                               </Form.Group>
//                               {errors.about && (
//                                 <span className="text-danger">
//                                   {errors.about.message}
//                                 </span>
//                               )}
//                             </Col>
//                           </Row>
//                         </div>
//                       </Col>

//                       <Col lg={6}>
//                         <div className="main-form-section mt-3">
//                           <Row className="justify-content-start">
//                             {" "}
//                             {/* Align to left */}
//                             {/* Description */}
//                             <Col sm={12}>
//                               <Form.Label className="text-center">
//                                 benifits
//                               </Form.Label>
//                               <Form.Group>
//                                 <Controller
//                                   name="benifits" // Provide the field name
//                                   control={control} // Pass the control object from useForm()
//                                   rules={{ required: "benifits is required" }} // Validation rules
//                                   render={({ field }) => (
//                                     <JoditEditor
//                                       value={field.value}
//                                       onChange={(newContent) =>
//                                         field.onChange(newContent)
//                                       }
//                                       onBlur={field.onBlur}
//                                       className={classNames("", {
//                                         "is-invalid": !!errors.benifits,
//                                       })}
//                                       placeholder="benifits"
//                                       config={{
//                                         readonly: true, // Set the editor to read-only mode
//                                         toolbar: false, // Hide the toolbar
//                                       }}
//                                     />
//                                   )}
//                                 />
//                               </Form.Group>
//                               {errors.benifits && (
//                                 <span className="text-danger">
//                                   {errors.benifits.message}
//                                 </span>
//                               )}
//                             </Col>
//                           </Row>
//                         </div>
//                       </Col>

//                       <Col lg={6}>
//                         <div className="main-form-section mt-3">
//                           <Row className="justify-content-start">
//                             {" "}
//                             {/* Align to left */}
//                             {/* Description */}
//                             <Col sm={12}>
//                               <Form.Label className="text-center">
//                                 storage
//                               </Form.Label>
//                               <Form.Group>
//                                 <Controller
//                                   name="storage" // Provide the field name
//                                   control={control} // Pass the control object from useForm()
//                                   rules={{ required: "storage is required" }} // Validation rules
//                                   render={({ field }) => (
//                                     <JoditEditor
//                                       value={field.value}
//                                       onChange={(newContent) =>
//                                         field.onChange(newContent)
//                                       }
//                                       onBlur={field.onBlur}
//                                       className={classNames("", {
//                                         "is-invalid": !!errors.storage,
//                                       })}
//                                       placeholder="storage"
//                                       config={{
//                                         readonly: true, // Set the editor to read-only mode
//                                         toolbar: false, // Hide the toolbar
//                                       }}
//                                     />
//                                   )}
//                                 />
//                               </Form.Group>
//                               {errors.storage && (
//                                 <span className="text-danger">
//                                   {errors.storage.message}
//                                 </span>
//                               )}
//                             </Col>
//                           </Row>
//                         </div>
//                       </Col>

//                       <div className="main-form-section mt-5"></div>
//                       <Card.Title>
//                         Product variants Details
//                         {variantsError && (
//                           <div className="text-danger">{variantsError}</div>
//                         )}
//                       </Card.Title>
//                       <hr />

//                       {fields.map((variant, index) => (
//                         <div
//                           key={variant.id}
//                           className="main-form-section mt-3"
//                         >
//                           <Row>
//                             <Col md={3}>
//                               <div className="main-form-section mt-3">
//                                 <Row className="justify-content-center">
//                                   <Form.Label>Variant Name</Form.Label>
//                                   <Form.Group>
//                                     <InputGroup>
//                                       <Form.Control
//                                         disabled
//                                         type="text"
//                                         name={`variants.${index}.v_name`} // Register color for the variant
//                                         placeholder="Variant Name"
//                                         className={classNames("", {
//                                           "is-invalid":
//                                             errors?.variants?.[index]?.v_name,
//                                         })}
//                                         {...register(
//                                           `variants.${index}.v_name`,
//                                           {
//                                             required:
//                                               "Variant name is required",
//                                           }
//                                         )}
//                                       />
//                                     </InputGroup>

//                                     {errors?.variants?.[index]?.v_name && (
//                                       <span className="text-danger">
//                                         {errors.variants[index].v_name.message}
//                                       </span>
//                                     )}
//                                   </Form.Group>
//                                 </Row>
//                               </div>
//                             </Col>

//                             <Col md={3}>
//                               <div className="main-form-section mt-3">
//                                 <Row className="justify-content-center">
//                                   <Form.Label>unit</Form.Label>
//                                   <Form.Group>
//                                     <InputGroup>
//                                       <Form.Control
//                                         disabled
//                                         type="number"
//                                         name={`variants.${index}.unit`}
//                                         placeholder="Unit"
//                                         className={classNames("", {
//                                           "is-invalid":
//                                             errors?.variants?.[index]?.unit, // Updated error handling
//                                         })}
//                                         {...register(`variants.${index}.unit`, {
//                                           required: "Unit is required",
//                                         })}
//                                       />
//                                     </InputGroup>
//                                     {errors?.variants?.[index]?.unit && (
//                                       <span className="text-danger">
//                                         {
//                                           errors?.variants?.[index]?.unit
//                                             .message
//                                         }
//                                       </span>
//                                     )}
//                                   </Form.Group>
//                                 </Row>
//                               </div>
//                             </Col>

//                             {/* MRP */}
//                             <Col md={3}>
//                               <div className="main-form-section mt-3">
//                                 <Row className="justify-content-center">
//                                   <Form.Label>mrp</Form.Label>
//                                   <Form.Group>
//                                     <InputGroup>
//                                       <Form.Control
//                                         disabled
//                                         type="number"
//                                         name={`variants.${index}.mrp`}
//                                         placeholder="mrp"
//                                         className={classNames("", {
//                                           "is-invalid":
//                                             errors?.variants?.[index]?.mrp, // Updated error handling
//                                         })}
//                                         {...register(`variants.${index}.mrp`, {
//                                           required: "MRP is required",
//                                         })}
//                                       />
//                                     </InputGroup>
//                                     {errors?.variants?.[index]?.mrp && (
//                                       <span className="text-danger">
//                                         {errors.variants[index].mrp.message}
//                                       </span>
//                                     )}
//                                   </Form.Group>
//                                 </Row>
//                               </div>
//                             </Col>

//                             {/* SKU */}
//                             <Col md={3}>
//                               <div className="main-form-section mt-3">
//                                 <Row className="justify-content-center">
//                                   <Form.Label>sku</Form.Label>
//                                   <Form.Group>
//                                     <InputGroup>
//                                       <Form.Control
//                                         disabled
//                                         type="text"
//                                         name={`variants.${index}.sku`}
//                                         placeholder="sku"
//                                         className={classNames("", {
//                                           "is-invalid":
//                                             errors?.variants?.[index]?.sku, // Updated error handling
//                                         })}
//                                         {...register(`variants.${index}.sku`, {
//                                           required: "sku is required",
//                                         })}
//                                       />
//                                     </InputGroup>
//                                     {errors?.variants?.[index]?.sku && (
//                                       <span className="text-danger">
//                                         {errors.variants[index].sku.message}
//                                       </span>
//                                     )}
//                                   </Form.Group>
//                                 </Row>
//                               </div>
//                             </Col>

//                             {/* Selling Prise */}
//                             <Col md={3}>
//                               <div className="main-form-section mt-3">
//                                 <Row className="justify-content-center">
//                                   <Form.Label>Selling Price</Form.Label>
//                                   <Form.Group>
//                                     <InputGroup>
//                                       <Form.Control
//                                         disabled
//                                         type="number"
//                                         name={`variants.${index}.s_price`}
//                                         placeholder="Selling Price"
//                                         className={classNames("", {
//                                           "is-invalid":
//                                             errors?.variants?.[index]?.s_price, // Updated error handling
//                                         })}
//                                         {...register(
//                                           `variants.${index}.s_price`,
//                                           {
//                                             required:
//                                               "Selling Price is required",
//                                             validate: validateSellingPrice,
//                                           }
//                                         )}
//                                       />
//                                     </InputGroup>
//                                     {errors?.variants?.[index]?.s_price && (
//                                       <span className="text-danger">
//                                         {errors.variants[index].s_price.message}
//                                       </span>
//                                     )}
//                                   </Form.Group>
//                                 </Row>
//                               </div>
//                             </Col>

//                             <Col lg={6}>
//                               <div className="main-form-section mt-3">
//                                 <Form.Label>images</Form.Label>

//                                 <Table striped bordered hover>
//                                   <thead>
//                                     <tr>
//                                       <th>Variant Images</th>
//                                       {/* <th>Action</th> */}
//                                     </tr>
//                                   </thead>
//                                   <tbody>
//                                     {fields[index]?.variant_images?.map(
//                                       (image, imageIndex) => (
//                                         <tr key={imageIndex}>
//                                           <td>
//                                             <img
//                                               src={IMG_URL + image.image}
//                                               alt={`Image ${imageIndex + 1}`}
//                                               style={{
//                                                 width: "50px",
//                                                 height: "50px",
//                                               }}
//                                             />
//                                           </td>
//                                         </tr>
//                                       )
//                                     )}
//                                   </tbody>
//                                 </Table>
//                               </div>
//                             </Col>
//                           </Row>
//                         </div>
//                       ))}

//                       {m_fields?.map((variant, m_index) => (
//                         <div
//                           key={variant.id}
//                           className="main-form-section mt-3"
//                         >
//                           <Row>
//                             <Col md={3}>
//                               <div className="main-form-section mt-3">
//                                 <Row className="justify-content-center">
//                                   <Form.Label>Variant Name</Form.Label>
//                                   <Form.Group>
//                                     <InputGroup>
//                                       <Form.Control
//                                         disabled
//                                         type="text"
//                                         placeholder="Variant Name"
//                                         {...register(
//                                           `m_main_variants.${m_index}.varient_name`
//                                         )}
//                                       />
//                                     </InputGroup>
//                                   </Form.Group>
//                                 </Row>
//                               </div>
//                             </Col>
//                           </Row>

//                           {variant?.m_variant?.map((subVariant, v_index) => (
//                             <div
//                               key={subVariant.id}
//                               className="main-form-section mt-3"
//                             >
//                               <Row>
//                                 {/* Variant Sub Name */}
//                                 <Col md={3}>
//                                   <div className="main-form-section mt-3">
//                                     <Row className="justify-content-center">
//                                       <Form.Label>Variant Sub Name</Form.Label>
//                                       <Form.Group>
//                                         <InputGroup>
//                                           <Form.Control
//                                             disabled
//                                             type="text"
//                                             placeholder="Variant Sub Name"
//                                             defaultValue={
//                                               subVariant.varient_sub_name
//                                             }
//                                           />
//                                         </InputGroup>
//                                       </Form.Group>
//                                     </Row>
//                                   </div>
//                                 </Col>

//                                 <Col md={3}>
//                                   <div className="main-form-section mt-3">
//                                     <Row className="justify-content-center">
//                                       <Form.Label>Unit</Form.Label>
//                                       <Form.Group>
//                                         <InputGroup>
//                                           <Form.Control
//                                             disabled
//                                             type="text"
//                                             placeholder="Variant Sub Name"
//                                             defaultValue={subVariant.unit}
//                                           />
//                                         </InputGroup>
//                                       </Form.Group>
//                                     </Row>
//                                   </div>
//                                 </Col>

//                                 <Col md={3}>
//                                   <div className="main-form-section mt-3">
//                                     <Row className="justify-content-center">
//                                       <Form.Label>Sku</Form.Label>
//                                       <Form.Group>
//                                         <InputGroup>
//                                           <Form.Control
//                                             disabled
//                                             type="text"
//                                             placeholder="Variant Sub Name"
//                                             defaultValue={subVariant.sku}
//                                           />
//                                         </InputGroup>
//                                       </Form.Group>
//                                     </Row>
//                                   </div>
//                                 </Col>

//                                 <Col md={3}>
//                                   <div className="main-form-section mt-3">
//                                     <Row className="justify-content-center">
//                                       <Form.Label>MRP</Form.Label>
//                                       <Form.Group>
//                                         <InputGroup>
//                                           <Form.Control
//                                             disabled
//                                             type="text"
//                                             placeholder="Variant Sub Name"
//                                             defaultValue={subVariant.mrp}
//                                           />
//                                         </InputGroup>
//                                       </Form.Group>
//                                     </Row>
//                                   </div>
//                                 </Col>

//                                 <Col md={3}>
//                                   <div className="main-form-section mt-3">
//                                     <Row className="justify-content-center">
//                                       <Form.Label>Selling Price</Form.Label>
//                                       <Form.Group>
//                                         <InputGroup>
//                                           <Form.Control
//                                             disabled
//                                             type="text"
//                                             placeholder="Variant Sub Name"
//                                             defaultValue={subVariant.s_price}
//                                           />
//                                         </InputGroup>
//                                       </Form.Group>
//                                     </Row>
//                                   </div>
//                                 </Col>

//                                 <Col lg={6}>
//                                   <div className="main-form-section mt-3">
//                                     <Form.Label>images</Form.Label>

//                                     <Table striped bordered hover>
//                                       <thead>
//                                         <tr>
//                                           <th>Variant Images</th>
//                                           {/* <th>Action</th> */}
//                                         </tr>
//                                       </thead>
//                                       <tbody>
//                                         {m_fields[m_index]?.m_variant[
//                                           v_index
//                                         ]?.m_variant_images?.map(
//                                           (image, imageIndex) => (
//                                             <tr key={imageIndex}>
//                                               <td>
//                                                 <img
//                                                   src={IMG_URL + image.image}
//                                                   alt={`Image ${
//                                                     imageIndex + 1
//                                                   }`}
//                                                   style={{
//                                                     width: "50px",
//                                                     height: "50px",
//                                                   }}
//                                                 />
//                                               </td>
//                                             </tr>
//                                           )
//                                         )}
//                                       </tbody>
//                                     </Table>
//                                   </div>
//                                 </Col>
//                               </Row>
//                             </div>
//                           ))}
//                         </div>
//                       ))}
//                       <div className="main-form-section mt-3"></div>
//                       <hr />

//                       <Col md={4}>
//                         <div className="main-form-section mt-3">
//                           <Form.Group>
//                             <Form.Label>Take a action</Form.Label>

//                             <InputGroup>
//                               <Controller
//                                 name="stage" // name of the field
//                                 {...register("stage", {
//                                   required: "Take an Action",
//                                 })}
//                                 control={control}
//                                 render={({ field }) => (
//                                   <Select
//                                     styles={{
//                                       control: (baseStyles) => ({
//                                         ...baseStyles,
//                                         borderColor: errors.stage
//                                           ? "red"
//                                           : baseStyles,
//                                       }),
//                                     }}
//                                     {...field}
//                                     options={[
//                                       { value: "Approved", label: "Approve" },
//                                       { value: "Rejected", label: "Reject" },
//                                     ]}
//                                   />
//                                 )}
//                               />
//                             </InputGroup>
//                             {errors?.stage && (
//                               <span className="text-danger">
//                                 {errors.stage.message}
//                               </span>
//                             )}
//                           </Form.Group>
//                         </div>
//                       </Col>

//                       <Row className="mt-5 pb-3">
//                         <div className="d-flex justify-content-center">
//                           <Link>
//                             <CancelButton
//                               name={"cancel"}
//                               handleClose={props.handleClose}
//                             />
//                           </Link>

//                           <Button
//                             name={"save"}
//                             onClick={handleSubmit(onSubmit)}
//                             type="button"
//                             className="save-btnnnnnn"
//                           >
//                             <FontAwesomeIcon
//                               icon="fa-solid fa-floppy-disk"
//                               className="me-2"
//                             />
//                             save
//                           </Button>
//                         </div>
//                       </Row>
//                     </Row>
//                   </Form>
//                 </Container>
//               </Card.Body>
//             </Card>
//           </Card>
//         </Offcanvas.Body>
//       </Offcanvas>

//       <ModalSave
//         message={showModal.message}
//         showErrorModal={showModal.code ? true : false}
//       />
//     </>
//   );
// };

// export default EditOffCanvance;

import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../common/ModelSave";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../common/Button";
import {
  Row,
  Col,
  Form,
  Modal,
  Container,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(fas);

const EditOffCanvance = (props) => {
  const {
    postData,
    getData,
    Select2Data,
    IMG_URL,
    getDimension,
    deleteData,
    isAllow,
    usertype,
  } = useContext(Context);

  // const [categories, setCategories] = useState([]);
  // const [subCategories, setSubCategories] = useState([]);
  // const [childSubCategories, setChildSubCategories] = useState([]);
  // const [brands, setBrands] = useState([]);
  // const [taxTypes, setTaxTypes] = useState([]);
  // const [taxPercentages, setPercentages] = useState([]);
  // const [attributes, setAttribute] = useState([]);
  // const [subAttributes, setSubAttribute] = useState([]);
  // const id = props.show;
  // const [role, setRole] = useState([]);
  // const [data, setData] = useState();
  // const [isDisabled, setIsDisabled] = useState(false);

  // const [user, setUser] = useState({});
  // const GetUserData = async () => {
  //   const response = await getData(`/userSingleget`);
  //   if (response?.success) {
  //     setUser(response?.data);
  //   }
  // };
  // useEffect(() => {
  //   GetUserData();
  // }, []);

  // useEffect(() => {
  //   if (user?.id === 1) {
  //     setIsDisabled(true);
  //   }
  // }, [user]);

  // const [showModal, setShowModal] = useState({
  //   code: 0,
  //   message: "",
  // });

  // const type = [
  //   {
  //     value: "Single",
  //     label: "Single",
  //   },
  //   {
  //     value: "multiple",
  //     label: "Multiple",
  //   },
  // ];

  // const {
  //   control,
  //   register,
  //   handleSubmit,
  //   getValues,
  //   formState: { errors },
  //   watch,
  //   setValue,
  //   reset,
  // } = useForm();

  // console.log(getValues("type"), "get values");
  // console.log(data?.type, "get values");
  // const imagesFile = watch("images");
  // const imageFile = watch("image1");
  // // console.log("imagesFile:", imagesFile);

  // console.log(getValues(), "getvalue");

  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "variants", // Name of your field array
  // });

  // const {
  //   fields: m_fields,
  //   append: m_append,
  //   remove: m_remove,
  // } = useFieldArray({
  //   control,
  //   name: "m_variants",
  // });

  // const addInnerVariant = () => {
  //   m_append({});
  // };

  // const [variantsError, setVariantsError] = useState("");

  // useEffect(() => {
  //   register("variants", {
  //     validate: (value) => {
  //       const isValid = value && value.length > 0;
  //       setVariantsError(isValid ? "" : "At least one variant is required");
  //       return isValid;
  //     },
  //   });
  // }, [register]);

  // const validateSellingPrice = (value, index) => {
  //   const mrpValue = parseFloat(getValues(`variants.${index}.mrp`));
  //   const sellingPrice = parseFloat(value);
  //   if (sellingPrice >= mrpValue) {
  //     return "Selling price must be less than MRP";
  //   }
  //   return true; // Validation passed
  // };

  // const onSubmit = async (data) => {
  //   try {
  //     const DataToSend = new FormData();
  //     DataToSend.append("name", data?.name);
  //     DataToSend.append("type", data?.type?.value);
  //     DataToSend.append("short_description", data?.short_description);
  //     DataToSend.append("manufacturer", data?.manufacturer);
  //     DataToSend.append("vender", data?.vender);
  //     DataToSend.append("about", data?.about);
  //     DataToSend.append("benifits", data?.benifits);
  //     DataToSend.append("storage", data?.storage);
  //     DataToSend.append("image1", data?.image1[0]);
  //     DataToSend.append("category_id", data?.category_id?.value);
  //     DataToSend.append("sub_category_id", data?.sub_category_id?.value);
  //     DataToSend.append(
  //       "child_sub_category_id",
  //       data?.child_sub_category_id?.value
  //     );
  //     DataToSend.append("brand_id", data?.brand_id?.value);
  //     DataToSend.append("tax_type_id", data?.tax_type_id?.value);
  //     DataToSend.append("tax_percentage_id", data?.tax_percentage_id?.value);

  //     console.log(data?.type, "shgdjfkgsdf sdf jsdtype");

  //     // Append variants
  //     const variants = [];
  //     data.variants.forEach((variant, index) => {
  //       variants.push({
  //         // attribute_id: variant.attribute_id?.value,
  //         // sub_attribute_id: variant.sub_attribute_id?.value,
  //         variant_id: variant?.id,
  //         v_name: variant.v_name,
  //         unit: variant.unit,
  //         mrp: variant.mrp,
  //         sku: variant.sku,
  //         s_price: variant.s_price,
  //       });

  //       let inputImages = document.getElementById(`variantImages${index}`);
  //       console.log(inputImages.files);
  //       const files = inputImages.files;
  //       for (let i = 0; i < files.length; i++) {
  //         DataToSend.append(`images${index}`, files[i]);
  //       }
  //       // if (variant?.variant_images?.length > 0) {
  //       //   for (let i = 0; i < variant?.variant_images?.length - 1; i++) {
  //       //     if (variant?.variant_images[i]) {
  //       //       DataToSend.append(`path${index}`, variant?.variant_images[i].image)
  //       //     }
  //       //   }
  //       // }
  //     });

  //     DataToSend.append("variants", JSON.stringify(variants));

  //     if (data.type?.value == "multiple") {
  //       const main_m_variants = [];

  //       data.main_m_variants.forEach((mainVariant, mainIndex) => {
  //         const m_variants = [];

  //         data.m_variants.forEach((variant, variantIndex) => {
  //           const variantData = {
  //             varient_sub_name: variant.varient_sub_name,
  //             unit: variant.unit,
  //             mrp: variant.mrp,
  //             sku: variant.sku,
  //             s_price: variant.s_price,
  //             images: [],
  //           };

  //           let inputImages = document.getElementById(
  //             `m_variantImages${variantIndex}`
  //           );
  //           const files = inputImages?.files;
  //           if (files) {
  //             for (let i = 0; i < files.length; i++) {
  //               // Append files to DataToSend with both mainIndex and variantIndex
  //               DataToSend.append(
  //                 `images${mainIndex}${variantIndex}${i}`,
  //                 files[0]
  //               );
  //               variantData.images.push(files[0].name);
  //             }
  //           }

  //           m_variants.push(variantData);
  //         });

  //         const mainVariantData = {
  //           varient_name: mainVariant.varient_name,
  //           m_variants: m_variants,
  //         };

  //         main_m_variants.push(mainVariantData);
  //       });

  //       DataToSend.append("main_m_variants", JSON.stringify(main_m_variants));
  //     }

  //     const response = await postData(`/product/${id}`, DataToSend);
  //     console.log("response", response);
  //     console.log("data to send", DataToSend);
  //     if (response?.success) {
  //       await setShowModal({ code: response.code, message: response.message });
  //     } else {
  //       await setShowModal({ code: response.code, message: response.message });
  //     }
  //     setTimeout(() => {
  //       setShowModal(0);
  //       props.handleClose();
  //     }, 1000);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const deleteImage = async (imageId) => {
  //   try {
  //     const response = await deleteData(`/product/product-image/${imageId}`);
  //     if (response.success) {
  //       console.log("Image deleted successfully");
  //     } else {
  //       console.error("Failed to delete image");
  //     }
  //     GetEditData();
  //   } catch (error) {
  //     console.error("Error deleting image:", error);
  //   }
  // };
  // const deleteLocalImage = async (index) => {
  //   const updatedImagesFile = [...imagesFile];
  //   updatedImagesFile.splice(index, 1);
  //   setValue("images", updatedImagesFile);
  // };

  // const GetAllCategory = async () => {
  //   const response = await getData("/allcategories");

  //   if (response?.success) {
  //     setCategories(await Select2Data(response?.data, "category_id"));
  //   }
  // };

  // const GetAllSubCategory = async (id) => {
  //   const response = await getData(`/allsubcategories/${id}`);

  //   if (response?.success) {
  //     setSubCategories(await Select2Data(response?.data, "sub_category_id"));
  //   }
  // };

  // const GetAllChildSubCategory = async (id) => {
  //   const response = await getData(`/allchildsubcategories/${id}`);

  //   if (response?.success) {
  //     setChildSubCategories(
  //       await Select2Data(response?.data, "child_sub_category_id")
  //     );
  //   }
  // };

  // const GetAllBrands = async () => {
  //   const response = await getData("/allbrands");

  //   if (response?.success) {
  //     setBrands(await Select2Data(response?.data, "brand_id"));
  //   }
  // };

  // const GetAllTaxTypes = async () => {
  //   const response = await getData("/all-tax-type");

  //   if (response?.success) {
  //     setTaxTypes(await Select2Data(response?.data, "tax_type_id"));
  //   }
  // };

  // const GetAllTaxPercentages = async (id) => {
  //   const response = await getData(`/all-tax-percentage/${id}`);

  //   if (response?.success) {
  //     setPercentages(await Select2Data(response?.data, "tax_percentage_id"));
  //   }
  // };

  // const GetAllAttributes = async () => {
  //   const response = await getData(`/all-attribute`);

  //   if (response?.success) {
  //     setAttribute(await Select2Data(response?.data, "attribute_id"));
  //   }
  // };

  // const GetAllSubAttributes = async (id) => {
  //   const response = await getData(`/all-sub-attribute/${id}`);

  //   if (response?.success) {
  //     setSubAttribute(await Select2Data(response?.data, "sub_attribute_id"));
  //   }
  // };

  // console.log(getValues("varient_sub_name"), "llll");

  // const GetEditData = async () => {
  //   const response = await getData(`/product/${id}`);
  //   setData(response);
  //   setData(response?.data);
  //   reset(response?.data);
  //   if (response?.data?.m_main_variants.length > 0) {
  //     response?.data?.m_main_variants.forEach((item, index) => {
  //       setValue("varient_name", item.varient_name);
  //       item?.m_variant.forEach((data, index) => {
  //         setValue("varient_sub_name", data.varient_sub_name);
  //       });
  //     });
  //   }
  // };

  // useEffect(() => {
  //   GetEditData();
  //   GetAllCategory();
  //   GetAllBrands();
  //   GetAllTaxTypes();
  //   GetAllAttributes();
  // }, []);

  // const handleRemoveVariant = async (index, variantId) => {
  //   // Remove the variant from the fields array
  //   console.log("variantId :-", variantId);
  //   remove(index);
  //   // try {
  //   // const vData = {
  //   //   variant_id: variantId,
  //   //   product_id: id
  //   // }
  //   // console.log(vData);
  //   // const response = await deleteData(`/product/delete-variant`, { vData });
  //   // if (response.success) {
  //   //   console.log("Image deleted successfully");
  //   // } else {
  //   //   console.error("Failed to delete image");
  //   // }
  //   // GetEditData();
  //   // } catch (error) {
  //   //   console.error("Error deleting image:", error);
  //   // }
  // };

  // ----------------------------------------------------------------
  const id = props.show;
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [childSubCategories, setChildSubCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [taxTypes, setTaxTypes] = useState([]);
  const [taxPercentages, setPercentages] = useState([]);
  const [attributes, setAttribute] = useState([]);
  const [subAttributes, setSubAttribute] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const GetAllCategory = async () => {
    const response = await getData("/allcategories");

    if (response?.success) {
      setCategories(await Select2Data(response?.data, "category_id"));
    }
  };

  const GetAllSubCategory = async (id) => {
    const response = await getData(`/allsubcategories/${id}`);

    if (response?.success) {
      setSubCategories(await Select2Data(response?.data, "sub_category_id"));
    }
  };

  const GetAllChildSubCategory = async (id) => {
    const response = await getData(`/allchildsubcategories/${id}`);

    if (response?.success) {
      setChildSubCategories(
        await Select2Data(response?.data, "sub_category_id")
      );
    }
  };

  const GetAllBrands = async () => {
    const response = await getData("/allbrands");

    if (response?.success) {
      setBrands(await Select2Data(response?.data, "brand_id"));
    }
  };

  const GetAllTaxTypes = async () => {
    const response = await getData("/all-tax-type");

    if (response?.success) {
      setTaxTypes(await Select2Data(response?.data, "tax_type_id"));
    }
  };

  const GetAllTaxPercentages = async (id) => {
    const response = await getData(`/all-tax-percentage/${id}`);

    if (response?.success) {
      setPercentages(await Select2Data(response?.data, "tax_percentage_id"));
    }
  };

  const GetAllAttributes = async () => {
    const response = await getData(`/all-attribute`);

    if (response?.success) {
      setAttribute(await Select2Data(response?.data, "attribute_id"));
    }
  };

  const GetAllSubAttributes = async (id) => {
    const response = await getData(`/all-sub-attribute/${id}`);

    if (response?.success) {
      setSubAttribute(await Select2Data(response?.data, "sub_attribute_id"));
    }
  };

  useEffect(() => {
    GetEditData();
    GetAllCategory();
    GetAllBrands();
    GetAllTaxTypes();
    GetAllAttributes();
  }, [id]);

  const GetEditData = async () => {
    const response = await getData(`/product/${id}`);
    // setData(response);
    // setData(response?.data);
    reset(response?.data);
    // if (response?.data?.m_main_variants.length > 0) {
    //   response?.data?.m_main_variants.forEach((item, index) => {
    //     setValue("varient_name", item.varient_name);
    //     item?.m_variant.forEach((data, index) => {
    //       setValue("varient_sub_name", data.varient_sub_name);
    //     });
    //   });
    // }
  };

  const type = [
    {
      value: "Single",
      label: "Single",
    },
    {
      value: "Multiple",
      label: "Multiple",
    },
  ];

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      category_id: "",
      type: {
        value: "Single",
        label: "Single",
      },
      main_m_variants: [
        {
          varient_name: "",
          m_variants: [
            {
              varient_sub_name: "",
              unit: "",
              mrp: "",
              sku: "",
              s_price: "",
              images: [],
            },
          ],
        },
      ],
    },
  });

  const imageFile = watch("image1");
  const imageFile2 = watch("images");

  console.log(watch("images"), "watch");

  const {
    fields: groupFields,
    append: appendGroup,
    remove: removeGroup,
  } = useFieldArray({
    control,
    name: "main_m_variants",
  });

  const handleAddGroup = () => {
    appendGroup({
      varient_name: "",
      m_variants: [
        {
          varient_sub_name: "",
          unit: "",
          mrp: "",
          sku: "",
          s_price: "",
          images: [],
        },
      ],
    });
  };

  // const onSubmit = async (data) => {
  //   try {
  //     const DataToSend = new FormData();

  //     DataToSend.append("name", data?.name);
  //     DataToSend.append("short_description", data?.short_description);
  //     DataToSend.append("manufacturer", data?.manufacturer);
  //     DataToSend.append("vender", data?.vender);
  //     DataToSend.append("about", data?.about);
  //     DataToSend.append("benifits", data?.benifits);
  //     DataToSend.append("storage", data?.storage);
  //     DataToSend.append("image1", data?.image1[0]);
  //     DataToSend.append("category_id", data?.category_id?.value);
  //     DataToSend.append("sub_category_id", data?.sub_category_id?.value);
  //     DataToSend.append(
  //       "child_sub_category_id",
  //       data?.child_sub_category_id?.value
  //     );
  //     DataToSend.append("brand_id", data?.brand_id?.value);
  //     DataToSend.append("tax_type_id", data?.tax_type_id?.value);
  //     DataToSend.append("tax_percentage_id", data?.tax_percentage_id?.value);
  //     DataToSend.append("type", data?.type?.value);

  //     const main_m_variants = [];

  //     data.main_m_variants.forEach((mainVariant, mainIndex) => {
  //       const m_variants = [];

  //       mainVariant.m_variants.forEach((variant, variantIndex) => {
  //         const variantData = {
  //           varient_sub_name: variant?.varient_sub_name,
  //           unit: variant?.unit,
  //           mrp: variant?.mrp,
  //           sku: variant?.sku,
  //           stock_limit: variant?.stock_limit,
  //           s_price: variant?.s_price,
  //           images: [],
  //         };

  //         for (let i = 0; variant?.image[i]; i++) {
  //           DataToSend.append(
  //             `images${mainIndex}${variantIndex}${i}`,
  //             variant?.image[i]
  //           );
  //           variantData.images.push(variant?.image[i]?.name);
  //         }

  //         m_variants.push(variantData);
  //       });

  //       const mainVariantData = {
  //         varient_name: mainVariant.varient_name,
  //         m_variants: m_variants,
  //       };

  //       main_m_variants.push(mainVariantData);
  //     });

  //     DataToSend.append("main_m_variants", JSON.stringify(main_m_variants));

  //     const response = await postData(`/product/${id}`, DataToSend);

  //     if (response?.success) {
  //       await setShowModal({ code: response?.code, message: response.message });
  //     } else {
  //       await setShowModal({ code: response?.code, message: response.message });
  //     }
  //     setTimeout(() => {
  //       setShowModal(0);
  //       props.handleClose();
  //     }, 1000);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const onSubmit = async (data) => {
    try {
      const dataToSend = new FormData();
      dataToSend.append("stage", data?.stage?.value);
      // dataToSend.append("email", data?.email)
      const response = await postData(`/product/request/${id}`, dataToSend);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Add Employee</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Add Product</Card.Title>
              <hr />
              <Container>
                <Form>
                  <Row>
                    <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Category</Form.Label>
                          <Controller
                            name="category_id"
                            {...register("category_id", {
                              required: "Select Category",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.category_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={categories}
                                isDisabled={true}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption.value);
                                  GetAllSubCategory(selectedOption.value);
                                  setValue("category_id", selectedOption);
                                  setValue("sub_category_id", "");
                                  setValue("child_sub_category_id", "");
                                }}
                              />
                            )}
                          />
                          {errors.category_id && (
                            <span className="text-danger">
                              {errors.category_id.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Sub Category</Form.Label>
                          <Controller
                            name="sub_category_id"
                            {...register("sub_category_id", {
                              required: "Select Sub Category",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.sub_category_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={subCategories}
                                isDisabled={true}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption.value);
                                  GetAllChildSubCategory(selectedOption.value);
                                  setValue("sub_category_id", selectedOption);
                                  setValue("child_sub_category_id", "");
                                }}
                              />
                            )}
                          />
                          {errors.sub_category_id && (
                            <span className="text-danger">
                              {errors.sub_category_id.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Name</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="name"
                                placeholder="Name .."
                                className={classNames("", {
                                  "is-invalid": errors?.name,
                                })}
                                {...register("name", {
                                  required: "Name is required",
                                })}
                                disabled
                              />
                            </InputGroup>
                            {errors.name && (
                              <span className="text-danger">
                                {errors.name.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Short Description</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="short_description"
                                placeholder="Short Description"
                                maxLength={40}
                                className={classNames("", {
                                  "is-invalid": errors?.short_description,
                                })}
                                disabled
                                {...register("short_description", {
                                  required: "Short Description is required",
                                })}
                              />
                            </InputGroup>
                            {errors.short_description && (
                              <span className="text-danger">
                                {errors.short_description.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Form.Label>Image</Form.Label>

                        <Form.Group>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.image1,
                            })}
                            type="file"
                            {...register("image1", {
                              // required: "images is required",
                            })}
                            disabled
                            accept="image/*"
                          />
                        </Form.Group>
                        {errors.image1 && (
                          <span className="text-danger">
                            {errors.image1.message}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col lg={3}>
                      <div className="main-form-section mt-3">
                        <Form.Label>Product Image</Form.Label>

                        {typeof getValues("image1") == "string" ? (
                          <div className="image-preview-container">
                            <img
                              src={IMG_URL + getValues("image1")}
                              alt="Preview"
                              className="image-preview"
                              style={{ width: "150px", height: "130px" }}
                            />
                          </div>
                        ) : (
                          imageFile &&
                          imageFile?.length > 0 && (
                            <div className="image-preview-container">
                              <img
                                // src={URL.createObjectURL(getValues("image")[0])}
                                src={URL?.createObjectURL(imageFile[0])}
                                alt="Preview"
                                className="image-preview"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Varient Type</Form.Label>
                          <Controller
                            name="type"
                            control={control}
                            rules={{ required: "Select Brand" }}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.type
                                      ? "red"
                                      : baseStyles.borderColor,
                                  }),
                                }}
                                {...field}
                                options={type}
                                isDisabled={true}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption.value);
                                  setValue("type", selectedOption);
                                  reset(getValues());
                                }}
                              />
                            )}
                          />
                          {errors.type && (
                            <span className="text-danger">
                              {errors.type.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>

                    <div className="main-form-section mt-5"></div>
                    <Card.Title>Product Details</Card.Title>
                    <hr />
                    <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>manufacturer</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="manufacturer"
                                placeholder="manufacturer"
                                className={classNames("", {
                                  "is-invalid": errors?.manufacturer,
                                })}
                                {...register("manufacturer", {
                                  required: "manufacturer is required",
                                })}
                                disabled
                              />
                            </InputGroup>
                            {errors.manufacturer && (
                              <span className="text-danger">
                                {errors.manufacturer.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>

                        <Row className="justify-content-center">
                          <Form.Label>vender</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="vender"
                                placeholder="vender"
                                className={classNames("", {
                                  "is-invalid": errors?.vender,
                                })}
                                {...register("vender", {
                                  required: "vender is required",
                                })}
                                disabled
                              />
                            </InputGroup>
                            {errors.vender && (
                              <span className="text-danger">
                                {errors.vender.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Brand</Form.Label>
                          <Controller
                            name="brand_id"
                            {...register("brand_id", {
                              required: "Select Brand",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.brand_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={brands}
                                isDisabled={true}
                              />
                            )}
                          />
                          {errors.brand_id && (
                            <span className="text-danger">
                              {errors.brand_id.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Tax Type</Form.Label>
                          <Controller
                            name="tax_type_id"
                            {...register("tax_type_id", {
                              required: "Select Tax Type",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.tax_type_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={taxTypes}
                                isDisabled={true}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption.value);
                                  GetAllTaxPercentages(selectedOption.value);
                                  setValue("tax_type_id", selectedOption);
                                }}
                              />
                            )}
                          />
                          {errors.tax_type_id && (
                            <span className="text-danger">
                              {errors.tax_type_id.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Tax Percentage</Form.Label>
                          <Controller
                            name="tax_percentage_id"
                            {...register("tax_percentage_id", {
                              required: "Select Tax Percentage",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.tax_percentage_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={taxPercentages}
                                isDisabled={true}
                              />
                            )}
                          />
                          {errors.tax_percentage_id && (
                            <span className="text-danger">
                              {errors.tax_percentage_id.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-start">
                          {/* Align to left */}
                          {/* Description */}
                          <Col sm={12}>
                            <Form.Label className="text-center">
                              about
                            </Form.Label>
                            <Form.Group>
                              <Controller
                                name="about"
                                control={control}
                                rules={{ required: "about is required" }}
                                render={({ field }) => (
                                  <JoditEditor
                                    value={field.value}
                                    onChange={(newContent) =>
                                      field.onChange(newContent)
                                    }
                                    onBlur={field.onBlur}
                                    disabled={true} // Add this line to disable the editor
                                    className={classNames("", {
                                      "is-invalid": !!errors.about,
                                    })}
                                    placeholder="about"
                                  />
                                )}
                              />
                            </Form.Group>
                            {errors.about && (
                              <span className="text-danger">
                                {errors.about.message}
                              </span>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-start">
                          <Col sm={12}>
                            <Form.Label className="text-center">
                              benifits
                            </Form.Label>
                            <Form.Group>
                              <Controller
                                name="benifits"
                                control={control}
                                rules={{ required: "benifits is required" }}
                                render={({ field }) => (
                                  <JoditEditor
                                    value={field.value}
                                    onChange={(newContent) =>
                                      field.onChange(newContent)
                                    }
                                    onBlur={field.onBlur}
                                    className={classNames("", {
                                      "is-invalid": !!errors.benifits,
                                    })}
                                    placeholder="benifits"
                                  />
                                )}
                              />
                            </Form.Group>
                            {errors.benifits && (
                              <span className="text-danger">
                                {errors.benifits.message}
                              </span>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-start">
                          <Col sm={12}>
                            <Form.Label className="text-center">
                              Storage
                            </Form.Label>
                            <Form.Group>
                              <Controller
                                name="storage"
                                control={control}
                                rules={{ required: "storage is required" }}
                                render={({ field }) => (
                                  <JoditEditor
                                    value={field.value}
                                    onChange={(newContent) =>
                                      field.onChange(newContent)
                                    }
                                    onBlur={field.onBlur}
                                    className={classNames("", {
                                      "is-invalid": !!errors.storage,
                                    })}
                                    disabled={true}
                                    placeholder="storage"
                                  />
                                )}
                              />
                            </Form.Group>
                            {errors.storage && (
                              <span className="text-danger">
                                {errors.storage.message}
                              </span>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <div className="main-form-section mt-5"></div>
                    <Card.Title>
                      Product Variants Details
                      {/* {variantsError && (
                          <div className="text-danger">{variantsError}</div>
                        )} */}
                    </Card.Title>
                    <hr />
                    {groupFields.map((variant, groupIndex) => (
                      <>
                        <div
                          key={groupIndex}
                          className="main-form-section mt-3"
                        >
                          <Row>
                            {getValues("type")?.value == "Single" ? (
                              ""
                            ) : (
                              <Col md={3}>
                                <div className="main-form-section mt-3">
                                  <Row className="justify-content-center">
                                    <Form.Label>Variant Name</Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          name={`main_m_variants.${groupIndex}.varient_name`}
                                          placeholder="Variant Name"
                                          className={classNames("", {
                                            "is-invalid":
                                              errors?.main_m_variants?.[
                                                groupIndex
                                              ]?.varient_name,
                                          })}
                                          disabled
                                          {...register(
                                            `main_m_variants.${groupIndex}.varient_name`,
                                            {
                                              required:
                                                "Variant name is required",
                                            }
                                          )}
                                        />
                                      </InputGroup>
                                      {errors?.main_m_variants?.[groupIndex]
                                        ?.varient_name && (
                                        <span className="text-danger">
                                          {
                                            errors.main_m_variants[groupIndex]
                                              .varient_name.message
                                          }
                                        </span>
                                      )}
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                            )}
                            <FieldArray
                              control={control}
                              groupIndex={groupIndex}
                              register={register}
                              errors={errors}
                              getValues={getValues}
                              imageFile2={imageFile2}
                              watch={watch}
                              setValue={setValue}
                              IMG_URL={IMG_URL}
                            />

                            {/* {getValues("type")?.value == "Single" ? (
                              ""
                            ) : (
                              <Col md={3}>
                                <button
                                  type="button"
                                  className="mt-3 add-varient"
                                  onClick={() => removeGroup(groupIndex)}
                                >
                                  Remove Group
                                </button>
                              </Col>
                            )} */}
                          </Row>
                        </div>
                        {/* <div key={group.id} style={{ marginBottom: "20px" }}>
                            <label>
                              Group Name:
                              <input
                                {...register(`variants.${groupIndex}.name`)}
                              />
                            </label>
                            <FieldArray
                              control={control}
                              groupIndex={groupIndex}
                              register={register}
                            />
                            <button
                              type="button"
                              onClick={() => removeGroup(groupIndex)}
                            >
                              Remove Group
                            </button>
                          </div> */}
                      </>
                    ))}
                    {/* {getValues("type")?.value == "Single" ? (
                      ""
                    ) : (
                      <button
                        type="button"
                        className="mt-3 add-varient"
                        onClick={() => handleAddGroup()}
                      >
                        Add Group
                      </button>
                    )} */}
                  </Row>

                  <Col md={4}>
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>Take a action</Form.Label>

                        <InputGroup>
                          <Controller
                            name="stage" // name of the field
                            {...register("stage", {
                              required: "Take an Action",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.stage
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={[
                                  { value: "Approved", label: "Approve" },
                                  { value: "Rejected", label: "Reject" },
                                ]}
                              />
                            )}
                          />
                        </InputGroup>
                        {errors?.stage && (
                          <span className="text-danger">
                            {errors.stage.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </Col>
                  {
                    <Row className="mt-5 pb-3">
                      <div className="d-flex justify-content-center">
                        <Link>
                          <CancelButton
                            name={"cancel"}
                            handleClose={props.handleClose}
                          />
                        </Link>

                        <Button
                          name={"save"}
                          onClick={handleSubmit(onSubmit)}
                          type="button"
                          className="save-btnnnnnn"
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-floppy-disk"
                            className="me-2"
                          />
                          save
                        </Button>
                      </div>
                    </Row>
                  }
                </Form>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

const FieldArray = ({
  control,
  groupIndex,
  register,
  errors,
  getValues,
  watch,
  IMG_URL,
  setValue,
}) => {
  const {
    fields: itemFields,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: `main_m_variants.${groupIndex}.m_variants`,
  });

  const handleAddItem = () => {
    appendItem({ value: "", m_variant_images: [] });
  };

  const handleMRPChange = (itemIndex, newMRP) => {
    const trade = parseFloat(
      getValues(
        `main_m_variants.${groupIndex}.m_variants.${itemIndex}.trade`
      ) || 0
    );

    const scheme = parseFloat(
      getValues(
        `main_m_variants.${groupIndex}.m_variants.${itemIndex}.scheme`
      ) || 0
    );
    const discount = parseFloat(
      getValues(
        `main_m_variants.${groupIndex}.m_variants.${itemIndex}.discount`
      ) || 0
    );

    // Calculate the scheme price as MRP - Scheme Percent
    const tradePrice = newMRP / trade;
    const schemePrice = tradePrice - tradePrice * (scheme / 100);
    const sellingPrice = schemePrice - discount;

    // Update the scheme price and selling price in the form
    setValue(
      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.scheme_price`,
      schemePrice.toFixed(2)
    );

    setValue(
      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.s_price`,
      sellingPrice.toFixed(2)
    );
  };

  // Watch the fields
  const watchItems = watch(
    `main_m_variants.${groupIndex}.m_variants`,
    itemFields
  );

  useEffect(() => {
    watchItems.forEach((item, itemIndex) => {
      const mrp = parseFloat(item.mrp || 0);
      handleMRPChange(itemIndex, mrp);
    });
  }, [watchItems, groupIndex, setValue]);

  return (
    <>
      {itemFields.map((item, itemIndex) => (
        <div key={item.id} className="main-form-section mt-3">
          <Row>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>
                    {" "}
                    {getValues("type")?.value == "Single" ? "" : "Sub"} Variant
                    Name
                  </Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.varient_sub_name`}
                        placeholder={
                          (getValues("type")?.value == "Single" ? "" : "Sub ") +
                          "Variant Name"
                        }
                        className={classNames("", {
                          "is-invalid":
                            errors.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.varient_sub_name,
                        })}
                        disabled
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.varient_sub_name`,
                          {
                            required: "Sub Variant name is required",
                          }
                        )}
                      />
                    </InputGroup>
                    {errors.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.varient_sub_name && (
                      <span className="text-danger">
                        {
                          errors.variants?.[groupIndex]?.m_variants?.[itemIndex]
                            ?.varient_sub_name?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Stock Unit</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.unit`}
                        placeholder="Stock Unit"
                        className={classNames("", {
                          "is-invalid":
                            errors.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.unit,
                        })}
                        disabled
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.unit`,
                          {
                            required: "Stock Unit is required",
                          }
                        )}
                      />
                    </InputGroup>
                    {errors.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.unit && (
                      <span className="text-danger">
                        {
                          errors.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.unit.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>{" "}
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>MRP</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        placeholder="MRP"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.mrp,
                        })}
                        disabled
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`,
                          {
                            required: "MRP is required",
                            onChange: (e) => {
                              const newMRP = parseFloat(e.target.value || 0);
                              handleMRPChange(itemIndex, newMRP);
                            },
                          }
                        )}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.mrp && (
                      <span className="text-danger">
                        {
                          errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.mrp?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Trade Percent</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        disabled
                        type="number"
                        placeholder="Scheme Percent"
                        max={100}
                        defaultValue={0}
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.trade,
                        })}
                        // disabled={usertype === "Admin"}
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.trade`,
                          {
                            required: "Trade Percent is required",
                            validate: {
                              minValue: (value) =>
                                value >= 0 ||
                                "Trade Percent cannot be Negative",
                              maxValue: (value) =>
                                value <= 100 ||
                                "Trade Percent cannot exceed 100",
                            },
                            onChange: (e) => {
                              const newScheme = parseFloat(e.target.value || 0);
                              const mrp = parseFloat(
                                getValues(
                                  `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`
                                ) || 0
                              );
                              handleMRPChange(itemIndex, mrp);
                            },
                          }
                        )}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.trade && (
                      <span className="text-danger">
                        {
                          errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.trade?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Scheme Percent</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        placeholder="Scheme Percent"
                        defaultValue={0}
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.scheme,
                        })}
                        disabled
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.scheme`,
                          {
                            required: "Scheme Percent is required",
                            onChange: (e) => {
                              const newScheme = parseFloat(e.target.value || 0);
                              const mrp = parseFloat(
                                getValues(
                                  `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`
                                ) || 0
                              );
                              handleMRPChange(itemIndex, mrp);
                            },
                          }
                        )}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.scheme && (
                      <span className="text-danger">
                        {
                          errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.scheme?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Discount In Rupees </Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="percent"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.discount`}
                        placeholder="Discount "
                        defaultValue={0}
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.discount,
                        })}
                        disabled
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.discount`,
                          {
                            required: "Discount  is required",
                            onChange: (e) => {
                              const newDiscount = parseFloat(
                                e.target.value || 0
                              );
                              const mrp = parseFloat(
                                getValues(
                                  `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`
                                ) || 0
                              );
                              handleMRPChange(itemIndex, mrp);
                            },
                          }
                        )}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.discount && (
                      <span className="text-danger">
                        {
                          errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.discount?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Scheme Price</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        placeholder="Selling Price"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.s_price,
                        })}
                        disabled
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.scheme_price`,
                          {
                            required: "Selling Price is required",
                            validate: (value) => {
                              return true;
                            },
                          }
                        )}
                        readOnly
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.s_price && (
                      <span className="text-danger">
                        {
                          errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.s_price?.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Selling Price</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.s_price`}
                        placeholder="Selling Price"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.s_price,
                        })}
                        disabled
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.s_price`,
                          {
                            required: "Selling Price is required",
                            validate: (value) => {
                              if (!value) return "Selling Price is required";
                              const minimumOrderValue = parseFloat(
                                getValues(
                                  `main_m_variants.${groupIndex}.m_variants.${itemIndex}.mrp`
                                )
                              );
                              if (parseFloat(value) <= 0) {
                                return "Selling Price must be a positive number";
                              }

                              // Check if discount is less than minimum order value
                              if (parseFloat(value) > minimumOrderValue) {
                                return "Selling Price must not be greater than MRP";
                              }

                              return true; // Return true for valid input
                            },
                          }
                        )}
                      />
                    </InputGroup>
                    {errors.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.s_price && (
                      <span className="text-danger">
                        {
                          errors.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.s_price.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>sku</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.sku`}
                        placeholder="sku"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.sku,
                        })}
                        disabled
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.sku`,
                          {
                            required: "sku is required",
                          }
                        )}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.sku && (
                      <span className="text-danger">
                        {
                          errors.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.sku.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Stock Limit</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.stock_limit`}
                        placeholder="Stock Limit"
                        className={classNames("", {
                          "is-invalid":
                            errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.stock_limit,
                        })}
                        disabled
                        {...register(
                          `main_m_variants.${groupIndex}.m_variants.${itemIndex}.stock_limit`,
                          {
                            required: "Stock Limit is required",
                          }
                        )}
                      />
                    </InputGroup>
                    {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                      itemIndex
                    ]?.stock_limit && (
                      <span className="text-danger">
                        {
                          errors.main_m_variants?.[groupIndex]?.m_variants?.[
                            itemIndex
                          ]?.stock_limit.message
                        }
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Form.Label>Images</Form.Label>
                <Form.Group>
                  <Form.Control
                    type="file"
                    multiple
                    accept="image/*"
                    className={classNames("", {
                      "is-invalid":
                        errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                          itemIndex
                        ]?.image,
                    })}
                    disabled
                    {...register(
                      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.image`,
                      {
                        onChange: (e) => {
                          const files = e.target.files;
                          const fileArray = Array.from(files).map((file) =>
                            URL.createObjectURL(file)
                          );

                          // Handle preview images
                          const newImages = [
                            ...watch(
                              `main_m_variants.${groupIndex}.m_variants.${itemIndex}.image`
                            ),
                          ];
                          newImages.push(...fileArray);
                        },
                      }
                    )}
                  />
                  {errors?.main_m_variants?.[groupIndex]?.m_variants?.[
                    itemIndex
                  ]?.image && (
                    <span className="text-danger">
                      {
                        errors.main_m_variants?.[groupIndex]?.m_variants?.[
                          itemIndex
                        ]?.images
                      }
                    </span>
                  )}
                </Form.Group>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Form.Label>Image Preview</Form.Label>
                <div className="image-preview-container">
                  {Array.isArray(
                    watch(
                      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.m_variant_images`
                    )
                  ) &&
                    watch(
                      `main_m_variants.${groupIndex}.m_variants.${itemIndex}.m_variant_images`
                    )?.map((image, index) => (
                      <img
                        key={index}
                        src={`${IMG_URL}${image?.image}`}
                        alt={`Preview ${index}`}
                        className="image-preview"
                        style={{ width: "100px", height: "100px" }}
                      />
                    ))}
                </div>
              </div>
            </Col>
            {/* <Col lg={3}>
              <div className="main-form-section mt-3">
            

                <Form.Group>
                  <button
                    type="button"
                    className="mt-3 add-varient"
                    onClick={() => removeItem(itemIndex)}
                  >
                    Remove Item
                  </button>
                </Form.Group>
              </div>
            </Col> */}
            {/* <label>
            Item Value:
            <input
              {...register(
                `variants.${groupIndex}.m_variants.${itemIndex}.value`
              )}
            />
          </label> */}
          </Row>
        </div>
      ))}
      {/* <Col md={3}>
        <button
          type="button"
          className="mt-3 add-varient"
          onClick={handleAddItem}
        >
          Add Item
        </button>
      </Col> */}
    </>
  );
};

export default EditOffCanvance;
