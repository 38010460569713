import React, { useContext, useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);

  const [features, setFeatures] = useState([]);
  const [memberHolder, setMemberHolder] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await postData(`/membership-details`, data);

      if (response?.success) {
        setShowModal({ code: response.code, message: response.message });
      } else {
        setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllFeatures = async () => {
    const response = await getData("/all-features");

    if (response?.success) {
      setFeatures(await Select2Data(response?.data, "membership_plan_id"));
    }
  };

  const GetAllFeatureMemberHolder = async () => {
    const response = await getData("/all-features-membership-holder");

    if (response?.success) {
      setMemberHolder(await Select2Data(response?.data, "membership_plan_id"));
    }
  };

  useEffect(() => {
    GetAllFeatures();
    GetAllFeatureMemberHolder();
  }, []);

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Membership details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Name</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Name"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
              <div>
                <h1>Features</h1>
                {features?.map((feature, featureIndex) => (
                  <Col lg={12} key={feature.id || featureIndex}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-start">
                        <Col>
                          <Form.Group>
                            <Form.Check
                              type="checkbox"
                              onChange={(e) => {
                                let ids = getValues(`features`) || [];
                                if (e.target.checked) {
                                  ids.push(feature.value);
                                } else {
                                  ids = ids.filter((id) => id !== feature.id);
                                }
                                setValue(`features`, ids);
                              }}
                              label={feature.label}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                ))}
              </div>

              <div>
                <h1>Membership Holder</h1>
                {memberHolder?.map((feature, featureIndex) => (
                  <Col lg={12} key={feature.id || featureIndex}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-start">
                        <Col>
                          <Form.Group>
                            <Form.Check
                              type="checkbox"
                              onChange={(e) => {
                                let ids = getValues(`m_holder`) || [];
                                if (e.target.checked) {
                                  ids.push(feature.value);
                                } else {
                                  ids = ids.filter((id) => id !== feature.id);
                                }
                                setValue(`m_holder`, ids);
                              }}
                              label={feature.label}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                ))}
              </div>
              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton name={"save"} />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
