import React from 'react'
import Lottie from "react-lottie";
import * as animationData from './Animation/LoginLottie.json';
import "../../Login/LoginPage/Animation/LoginLottie.css"

const LoginLottie = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <section className='main-lottey'>
                <div className='success mx-auto '>
                    {/* <Lottie
                        options={defaultOptions}
                    /> */}
                     <div className="">
                <img src={process.env.PUBLIC_URL + "/logo.png"} className="logoimgg"/>
              </div>
                </div>
            </section>
        </>
    )
}

export default LoginLottie