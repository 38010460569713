import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);
  const [plans, setPlans] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    getValues,
  } = useForm();
  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const response = await postData(`/membership-details/${id}`, data);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/membership-details/${id}`);
    reset(response?.data);
  };

  const GetAllCategory = async () => {
    const response = await getData("/all-plans");

    if (response?.success) {
      setPlans(await Select2Data(response?.data, "membership_plan_id"));
    }
  };

  const [features, setFeatures] = useState([]);
  const [memberHolder, setMemberHolder] = useState([]);

  const GetAllFeatures = async () => {
    const response = await getData("/all-features");
    if (response?.success) {
      setFeatures(response?.data);
    }
  };

  const GetAllFeatureMemberHolder = async () => {
    const response = await getData("/all-features-membership-holder");
    if (response?.success) {
      setMemberHolder(response?.data);
    }
  };

  // const GetAllFeatureMemberHolder = async () => {
  //   const response = await getData("/all-features-membership-holder");

  //   if (response?.success) {
  //     setMemberHolder(await Select2Data(response?.data, "membership_plan_id"));
  //   }
  // };

  useEffect(() => {
    GetAllCategory();
    GetEditData();
    GetAllFeatures();
    GetAllFeatureMemberHolder();
  }, []);
  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Membership Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label> Name</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder=" Offer"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
              <div>
                <h1>Features</h1>
                {features?.map((feature, featureIndex) => (
                  <Col lg={12} key={feature?.id || featureIndex}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-start">
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            defaultChecked={getValues(`features`)?.includes(
                              feature?.id
                            )}
                            onChange={(e) => {
                              let ids = getValues(`features`) || [];
                              if (e.target.checked) {
                                ids.push(feature.id);
                              } else {
                                ids = ids.filter((id) => id !== feature?.id);
                              }
                              setValue(`features`, ids);
                            }}
                            label={feature?.name}
                          />
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>
                ))}
              </div>
              <div>
                {" "}
                <h1>Membership Holders</h1>
                {memberHolder?.map((feature, featureIndex) => (
                  <Col lg={12} key={feature?.id || featureIndex}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-start">
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            defaultChecked={getValues(`m_holder`)?.includes(
                              feature?.id
                            )}
                            onChange={(e) => {
                              let ids = getValues(`m_holder`) || [];
                              if (e.target.checked) {
                                ids.push(feature.id);
                              } else {
                                ids = ids.filter((id) => id !== feature?.id);
                              }
                              setValue(`m_holder`, ids);
                            }}
                            label={feature?.name}
                          />
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>
                ))}
              </div>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
