import React, { useContext, useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import {
  Row,
  Col,
  Form,
  Modal,
  Container,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImageValidation } from "../../../utils/common";
import { ValidationImage } from "../../../utils/api";

const AddOffCanvance = (props) => {
  const {
    postData,
    getData,
    Select2Data,
    getDimension,
    SelectUsers,
    SelectSingleVarient,
    SelectMultipleMainVarient,
    SelectMultipleSubVarient,
    SelectProduct,
  } = useContext(Context);

  const [supplier, setSupplier] = useState([]);
  const [product, setProduct] = useState([]);
  const [type, setType] = useState("");
  const [singleVarient, setSingleVarient] = useState([]);
  const [multiMainVarient, setMultiMainVarient] = useState([]);
  const [multiSubVarient, setMultiSubVarient] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      category_id: "",
      type: {
        value: "Single",
        label: "Single",
      },
      main_m_variants: [
        {
          varient_name: "",
          m_variants: [
            {
              product_single_v_id: "",
              unit: "",
            },
          ],
        },
      ],
    },
  });

  const {
    fields: groupFields,
    append: appendGroup,
    remove: removeGroup,
  } = useFieldArray({
    control,
    name: "main_m_variants",
  });

  const handleAddGroup = () => {
    appendGroup({
      varient_name: "",
      m_variants: [
        {
          varient_sub_name: "",
          unit: "",
          mrp: "",
          sku: "",
          s_price: "",
          images: [],
        },
      ],
    });
  };

  const [quantityError, setQuantityError] = useState("");

  const [addOnError, setAddOnError] = useState("");

  useEffect(() => {
    register("main_m_variants", {
      validate: (value) => {
        const isValid = value && value.length > 0;
        setAddOnError(isValid ? "" : "At least one Product is required");
        return isValid;
      },
    });
  }, [register]);

  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();

      DataToSend.append("supplier_id", data?.supplier_id?.value);
      DataToSend.append("product_id", data?.product_id?.value);
      DataToSend.append("type", type);

      const main_m_variants = [];

      if (type === "Single") {
        data.main_m_variants.forEach((variant) => {
          const addOn = {
            unit: variant.unit,
            product_single_v_id: variant.product_single_v_id?.value,
          };
          main_m_variants.push(addOn);
        });
      }

      if (type === "Multiple") {
        data.main_m_variants.forEach((mainVariant) => {
          const m_variants = mainVariant.m_variants.map((variant) => ({
            product_m_sub_v_id: variant?.product_m_sub_v_id?.value,
            unit: variant?.unit,
          }));

          const mainVariantData = {
            product_m_main_id: mainVariant.product_m_main_id?.value,
            m_variants: m_variants,
          };

          main_m_variants.push(mainVariantData);
        });
      }

      DataToSend.append("main_m_variants", JSON.stringify(main_m_variants));

      const response = await postData(
        `/stock-management/order-purchase`,
        DataToSend
      );

      if (response?.success) {
        setShowModal({
          code: response?.code,
          message: response?.message,
        });
      } else {
        setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal({ code: 0, message: "" });
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  // const type = [
  //   {
  //     value: "Single",
  //     label: "Single",
  //   },
  //   {
  //     value: "Multiple",
  //     label: "Multiple",
  //   },
  // ];

  const GetAllSupplier = async () => {
    const response = await getData("/all-suppliers");
    if (response?.success) {
      setSupplier(await SelectUsers(response?.data, "supplier_id"));
    }
  };

  const GetAllProducts = async () => {
    const response = await getData("/all-products");
    if (response?.success) {
      setProduct(await SelectProduct(response?.data, "product_id"));
    }
  };

  useEffect(() => {
    GetAllSupplier();
    GetAllProducts();
  }, []);

  const GetAllSingleVarient = async (id) => {
    const response = await getData(`/all-single-varient/${id}`);
    if (response?.success) {
      setSingleVarient(
        await SelectSingleVarient(response?.data, "product_single_v_id")
      );
    }
  };

  const GetAllMultipleMainVarient = async (id) => {
    const response = await getData(`/all-multiple-main-varient/${id}`);
    if (response?.success) {
      setMultiMainVarient(
        await SelectMultipleMainVarient(response?.data, "product_m_main_id")
      );
    }
  };

  const GetAllMultipleSubVarient = async (id) => {
    const response = await getData(`/all-multiple-sub-varient/${id}`);
    if (response?.success) {
      setMultiSubVarient(
        await SelectMultipleSubVarient(response?.data, "product_m_sub_v_id")
      );
    }
  };

  return (
    <>
      <div className="prodcuttttt">
        <Offcanvas
          show={props.show}
          style={{ width: "80%" }}
          placement={"end"}
          onHide={props.handleClose}
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>Add Employee</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>Add Order Purchase</Card.Title>
                <hr />
                <Container>
                  <Form
                    // onSubmit={() => handleSubmit(onSubmit)}
                    role="form"
                    // className="stateclass"
                  >
                    <Row>
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Supplier</Form.Label>
                            <Controller
                              name="supplier_id" // name of the field
                              {...register("supplier_id", {
                                required: "Select Supplier",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.supplier_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={supplier}
                                />
                              )}
                            />
                            {errors.supplier_id && (
                              <span className="text-danger">
                                {errors.supplier_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Product</Form.Label>
                            <Controller
                              name="product_id" // name of the field
                              control={control}
                              rules={{ required: "Select Product" }}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.product_id
                                        ? "red"
                                        : baseStyles.borderColor,
                                    }),
                                  }}
                                  {...field}
                                  options={product}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption);
                                    GetAllSingleVarient(selectedOption.value);
                                    GetAllMultipleMainVarient(
                                      selectedOption.value
                                    );
                                    setType(selectedOption?.type);
                                  }}
                                />
                              )}
                            />
                            {errors.product_id && (
                              <span className="text-danger">
                                {errors.product_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Type</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="type"
                                  // placeholder="type .."
                                  className={classNames("", {
                                    "is-invalid": errors?.type,
                                  })}
                                  disabled
                                  value={type}
                                  {...register("type", {
                                    required: "Name is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.type && (
                                <span className="text-danger">
                                  {errors.type.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <div className="main-form-section mt-5"></div>
                      <Card.Title>
                        Products
                        {addOnError && (
                          <div className="text-danger">{addOnError}</div>
                        )}
                      </Card.Title>
                      <hr />

                      {groupFields.map((variant, index) => (
                        <div
                          key={variant.id}
                          className="main-form-section mt-3"
                        >
                          <Row>
                            {type == "Single" ? (
                              <>
                                <Col md={3}>
                                  <div className="main-form-section mt-3">
                                    <Row className="row justify-content-center mb-2">
                                      <Form.Label>Single Varient</Form.Label>
                                      <Controller
                                        name={`main_m_variants.${index}.product_single_v_id`} // name of the field
                                        {...register(
                                          `main_m_variants.${index}.product_single_v_id`,
                                          {
                                            required: "Select Add On",
                                          }
                                        )}
                                        control={control}
                                        render={({ field }) => (
                                          <Select
                                            styles={{
                                              control: (baseStyles) => ({
                                                ...baseStyles,
                                                borderColor: errors
                                                  ?.main_m_variants?.[index]
                                                  ?.product_single_v_id
                                                  ? "red"
                                                  : baseStyles,
                                              }),
                                            }}
                                            {...field}
                                            options={singleVarient}
                                          />
                                        )}
                                      />
                                      {errors?.main_m_variants?.[index]
                                        ?.product_single_v_id && (
                                        <span className="text-danger">
                                          {
                                            errors?.main_m_variants?.[index]
                                              ?.product_single_v_id.message
                                          }
                                        </span>
                                      )}
                                    </Row>
                                  </div>
                                </Col>
                              </>
                            ) : (
                              <Col md={3}>
                                <div className="main-form-section mt-3">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label>Add multiple main</Form.Label>
                                    <Controller
                                      name={`main_m_variants.${index}.product_m_main_id`} // name of the field
                                      {...register(
                                        `main_m_variants.${index}.product_m_main_id`,
                                        {
                                          required: "Select Add On",
                                        }
                                      )}
                                      control={control}
                                      render={({ field }) => (
                                        <Select
                                          styles={{
                                            control: (baseStyles) => ({
                                              ...baseStyles,
                                              borderColor: errors
                                                ?.main_m_variants?.[index]
                                                ?.product_m_main_id
                                                ? "red"
                                                : baseStyles.borderColor,
                                            }),
                                          }}
                                          {...field}
                                          options={multiMainVarient}
                                          onChange={(option) => {
                                            field.onChange(option);
                                            GetAllMultipleSubVarient(
                                              option.value
                                            );
                                          }}
                                        />
                                      )}
                                    />
                                    {errors?.main_m_variants?.[index]
                                      ?.product_m_main_id && (
                                      <span className="text-danger">
                                        {
                                          errors?.main_m_variants?.[index]
                                            ?.product_m_main_id.message
                                        }
                                      </span>
                                    )}
                                  </Row>
                                </div>
                              </Col>
                            )}

                            <FieldArray
                              control={control}
                              groupIndex={index}
                              register={register}
                              errors={errors}
                              getValues={getValues}
                              product={product}
                              multiSubVarient={multiSubVarient}
                              type={type}
                            />

                            {
                              <Col md={3}>
                                <button
                                  type="button"
                                  className="mt-3 add-varient"
                                  onClick={() => removeGroup(index)}
                                >
                                  Remove Group
                                </button>
                              </Col>
                            }
                          </Row>
                        </div>
                      ))}

                      <div className="main-form-section mt-3"></div>
                      <hr />
                      <div className="text-center">
                        <button
                          type="button"
                          className="mt-3 add-varient"
                          onClick={() => handleAddGroup()}
                        >
                          Add Group
                        </button>
                      </div>

                      <Row className="mt-5 pb-3">
                        <div className="d-flex justify-content-center">
                          <Link>
                            <CancelButton
                              name={"cancel"}
                              handleClose={props.handleClose}
                            />
                          </Link>

                          <Button
                            name={"save"}
                            onClick={handleSubmit(onSubmit)}
                            type="button"
                            className="save-btnnnnnn me-2"
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-floppy-disk"
                              className="me-2"
                            />
                            save
                          </Button>
                        </div>
                      </Row>

                      <hr />
                    </Row>
                  </Form>
                </Container>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Offcanvas>

        <ModalSave
          message={showModal.message}
          showErrorModal={showModal.code ? true : false}
        />
      </div>
    </>
  );
};

const FieldArray = ({
  control,
  groupIndex,
  register,
  errors,
  getValues,
  product,
  multiSubVarient,
  type,
}) => {
  const {
    fields: itemFields,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: `main_m_variants.${groupIndex}.m_variants`,
  });

  const handleAddItem = () => {
    appendItem({ value: "" });
  };

  return (
    <>
      {itemFields.map((item, itemIndex) => (
        <div key={item.id} className="main-form-section mt-3">
          <Row>
            {type == "Multiple" && (
              <Col md={3}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Multiple Sub Varient</Form.Label>
                    <Controller
                      name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.product_m_sub_v_id`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <Select
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors[
                                  `main_m_variants.${groupIndex}.m_variants.${itemIndex}.product_m_sub_v_id`
                                ]
                                  ? "red"
                                  : baseStyles.borderColor,
                              }),
                            }}
                            {...field}
                            options={multiSubVarient}
                          />
                          {errors[
                            `main_m_variants.${groupIndex}.m_variants.${itemIndex}.product_m_sub_v_id`
                          ] && (
                            <span className="text-danger">
                              {
                                errors[
                                  `main_m_variants.${groupIndex}.m_variants.${itemIndex}.product_m_sub_v_id`
                                ].message
                              }
                            </span>
                          )}
                        </>
                      )}
                      rules={{
                        required: "Select Tax Type",
                      }}
                    />
                  </Row>
                </div>
              </Col>
            )}
            {type == "Single" ? (
              <Col md={3}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Stock Unit</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name={`main_m_variants.${groupIndex}.unit`}
                          placeholder="Stock Unit"
                          className={classNames("", {
                            "is-invalid":
                              errors.main_m_variants?.[groupIndex]?.unit,
                          })}
                          {...register(`main_m_variants.${groupIndex}.unit`, {
                            required: "Stock Unit is required",
                            validate: (value) =>
                              value >= 0 || "Stock Unit cannot be negative",
                          })}
                        />
                      </InputGroup>
                      {errors.main_m_variants?.[groupIndex]?.unit && (
                        <span className="text-danger">
                          {errors.main_m_variants?.[groupIndex]?.unit.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
            ) : (
              <Col md={3}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Multiple Stock Unit</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name={`main_m_variants.${groupIndex}.m_variants.${itemIndex}.unit`}
                          placeholder="Stock Unit"
                          className={classNames("", {
                            "is-invalid":
                              errors.main_m_variants?.[groupIndex]
                                ?.m_variants?.[itemIndex]?.unit,
                          })}
                          {...register(
                            `main_m_variants.${groupIndex}.m_variants.${itemIndex}.unit`,
                            {
                              required: "Stock Unit is required",
                              validate: (value) =>
                                value >= 0 || "Stock Unit cannot be negative",
                            }
                          )}
                        />
                      </InputGroup>
                      {errors.main_m_variants?.[groupIndex]?.m_variants?.[
                        itemIndex
                      ]?.unit && (
                        <span className="text-danger">
                          {
                            errors.main_m_variants?.[groupIndex]?.m_variants?.[
                              itemIndex
                            ]?.unit.message
                          }
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
            )}
            {type == "Multiple" && (
              <Col lg={3}>
                <div className="main-form-section mt-3">
                  {/* <Form.Label></Form.Label> */}

                  <Form.Group>
                    <button
                      type="button"
                      className="mt-3 add-varient"
                      onClick={() => removeItem(itemIndex)}
                    >
                      Remove Item
                    </button>
                  </Form.Group>
                </div>
              </Col>
            )}
          </Row>
        </div>
      ))}
      {type == "Multiple" && (
        <Col md={3}>
          <button
            type="button"
            className="mt-3 add-varient"
            onClick={handleAddItem}
          >
            Add Item
          </button>
        </Col>
      )}
    </>
  );
};

export default AddOffCanvance;
