import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import pen from "../../../Components/assets/icons/pen.png";
import eye from "../../../Components/assets/icons/eye.png";
import basket from "../../../Components/assets/icons/basket.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../../assets/icons/circle.png";
import rigth from "../../assets/icons/rigth.png";
import save from "../../assets/icons/save.png";
import cancel from "../../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Header/Header";
import Modal from "react-bootstrap/Modal";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Offcanvas from "react-bootstrap/Offcanvas";

import toast, { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Context } from "../../../utils/context";
import { FaEye } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form";
import { postData } from "../../../utils/api";
import { toUnitless } from "@mui/material/styles/cssUtils";
import Successfull_Modal from "../../common/Successfull_Modal/Successfull_Modal";

library.add(fas);

// ********************************************************************************************************************************************************

const Tables = (props) => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    IMG_URL,
    Per_Page_Dropdown,
    Select2Data,
    SelectUsers,
  } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();

  const [data, setData] = useState([]);
  const [receivedData, setReceivedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [resetData, setResetData] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();
  const [searchByUser, setSearchByUser] = useState(null);
  const [searchCategory, setSearchCategory] = useState("");
  const [searchSupplier, setSearchSupplier] = useState("");
  const [searchSubCategory, setSearchSubCategory] = useState("");
  const [searchChildSubCategory, setSearchChildSubCategory] = useState("");

  const { id } = useParams();

  useEffect(() => {
    setSearchByUser(id || null); // Set to null if id is undefined or null
    getDataAll();
  }, [id]);

  useEffect(() => {
    getDataAll();
  }, [searchByUser]);

  const [user, setUser] = useState({});
  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    if (response?.success) {
      setUser(response?.data);
    }
  };
  useEffect(() => {
    GetUserData();
  }, []);

  const [changeStatus, setChangeStatus] = useState();
  const [changeReceiveStatus, setChangeReceiveStatus] = useState();
  const [modalShow, setModalShow] = useState(false);

  const getDataAll = async () => {
    const queryString = `/stock-management/order-purchase?page=${currentPage}&per_page=${perPage}&term=${search}&searchByUser=${id}&searchSupplier=${
      searchSupplier?.value || ""
    }&searchSubCategory=${
      searchSubCategory?.value || ""
    }&searchChildSubCategory=${searchChildSubCategory?.value || ""}`;
    const response = await getData(queryString);
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };
  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    perPage,
    resetData,
    show,
    show1,
    search,
    searchCategory,
    searchSubCategory,
    searchChildSubCategory,
    searchSupplier,
  ]);

  const [productOption, setProduct] = useState([]);
  const GetAllProducts = async () => {
    const response = await getData("/all-products");
    if (response?.success) {
      setProduct(await Select2Data(response?.data, "product_id"));
    }
  };

  useEffect(() => {
    GetAllProducts();
  }, []);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(
      `/stock-management/order-purchase/${id}`
    );
    setChangeStatus(response);
  };

  // const ChangeStatusReceive = async (data) => {
  //   const DataToSend = new FormData();
  //   const product = [];
  //   setTimeout(() => {
  //     setShowStatus(false);
  //     setModalShow(true);
  //   }, 2000); // Delay in milliseconds (e.g., 2000 ms = 2 seconds)

  //   if (getValues("type")?.value === "Single") {
  //     data.product?.forEach((item) => {
  //       const addOn = {
  //         unit: item.unit,
  //         product_single_v_id: item.product_single_v_id.value,
  //       };
  //       product.push(addOn);
  //     });
  //   }

  //   if (getValues("type")?.value === "Multiple") {
  //     data.product.forEach((mainVariant) => {
  //       const m_variants = mainVariant.m_sub_variants?.map((variant) => ({
  //         product_m_sub_v_id: variant?.product_m_sub_v_id?.value,
  //         unit: variant?.unit,
  //       }));

  //       const mainVariantData = {
  //         product_m_main_id: mainVariant.product_m_main_id?.value,
  //         m_sub_variants: m_variants,
  //       };

  //       product.push(mainVariantData);
  //     });
  //   }

  //   DataToSend.append("product", JSON.stringify(product));
  //   DataToSend.append("id", data.id);

  //   ChangeStatus(data.id);

  //   try {
  //     const response = await postData(
  //       `/stock-management/order-purchase/receive/${data.id}`,
  //       DataToSend
  //     );
  //     const result = await response.json();
  //     setChangeReceiveStatus(result);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const ChangeStatusReceive = async (data) => {
    const DataToSend = new FormData();
    const product = [];

    // Show the modal after 2 seconds
    // setTimeout(() => {
    setModalShow(true);
    setShowStatus(false);

    // Close the modal after 3 seconds
    setTimeout(() => {
      setModalShow(false);
      window.location.reload();
    }, 3000); // 3000 ms = 3 seconds
    // }, 2000); // Delay to show modal (2 seconds)

    if (getValues("type")?.value === "Single") {
      data.product?.forEach((item) => {
        const addOn = {
          unit: item.unit,
          product_single_v_id: item.product_single_v_id.value,
        };
        product.push(addOn);
      });
    }

    if (getValues("type")?.value === "Multiple") {
      data.product.forEach((mainVariant) => {
        const m_variants = mainVariant.m_sub_variants?.map((variant) => ({
          product_m_sub_v_id: variant?.product_m_sub_v_id?.value,
          unit: variant?.unit,
        }));

        const mainVariantData = {
          product_m_main_id: mainVariant.product_m_main_id?.value,
          m_sub_variants: m_variants,
        };

        product.push(mainVariantData);
      });
    }

    DataToSend.append("product", JSON.stringify(product));
    DataToSend.append("id", data.id);

    ChangeStatus(data.id);

    try {
      const response = await postData(
        `/stock-management/order-purchase/receive/${data.id}`,
        DataToSend
      );
      const result = await response.json();
      setChangeReceiveStatus(result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const GetEditData = async (id) => {
    const response = await getData(
      `/stock-management/order-purchase/receive/${id}`
    );

    setReceivedData(response?.data);
    reset(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  // Delete module.....................................................
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const DeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/stock-management/order-purchase/${recordToDeleteId}`
      );
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [supplier, setSupplier] = useState([]);

  const GetAllSupplier = async () => {
    const response = await getData("/all-suppliers");
    if (response?.success) {
      setSupplier(await SelectUsers(response?.data, "supplier_id"));
    }
  };

  useEffect(() => {
    GetAllSupplier();
  }, []);

  const [showStatus, setShowStatus] = useState(false);

  const handleCloseStatus = () => setShowStatus(false);
  const handleShowStatus = () => setShowStatus(true);

  return (
    <>
      <div className="main-advancedashboard">
        <Header
          title={"Order Purchase"}
          link={"/stock-management/order-purchase"}
        />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <div className="row MainRowsec">
              <section className="Tabels tab-radio tab-radio mt-5">
                <div className="">
                  {/* container */}
                  <div className="row">
                    {isAllow.includes(250) ? (
                      <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
                        <Link
                          // to="/employee/employee_details/add"
                          type="button"
                          className="btn btn-add pe-3"
                        >
                          <div className="add me-3">
                            <div onClick={() => handleShow()}>
                              <p className="add-sub-txttt">
                                {" "}
                                <img
                                  src={plus}
                                  className="plus me-2 ms-0"
                                  alt=""
                                />
                                Add Order purchase
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="add">
                        <div className="dropdown">
                          <button
                            className="btn btn-columns dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={colunms}
                              className="columns me-2 "
                              alt=""
                            />
                            Column Selection{" "}
                            <img src={top} className="top ms-1" alt="" />{" "}
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col1")}
                                href="#"
                              >
                                Sr. No.
                                {visible?.col1 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col2")}
                                href="#"
                              >
                                Supplier
                                {visible?.col2 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col4")}
                                href="#"
                              >
                                Batch Number
                                {visible?.col4 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col5")}
                                href="#"
                              >
                                Products
                                {visible?.col5 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col6")}
                                href="#"
                              >
                                Quantity
                                {visible?.col6 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col8")}
                                href="#"
                              >
                                Status
                                {visible?.col8 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col9")}
                                href="#"
                              >
                                Action
                                {visible?.col9 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-4">
                      <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-12 col-12">
                        <div className="d-flex">
                          <div className="show me-2">
                            <p className="show mt-1">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries">
                            <p className="show mt-1">entries</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-10 col-xl-10  col-lg-9  col-md-12 col-12">
                        <div className="row">
                          <div className="col-xxl-1 col-xl-1  col-lg-3  col-md-2 col-12">
                            <div className="sowing me-2">
                              <p className="show mt-2">{`Showing ${Math.min(
                                (currentPage - 1) * perPage + 1
                              )} to ${Math.min(
                                currentPage * perPage,
                                data?.data?.totalEntries
                              )} of ${data?.data?.totalEntries} entries`}</p>
                            </div>
                          </div>
                          <div className="col-xxl-3 col-xl-3  col-lg-4  col-md-5 col-12 mt-md-0 mt-3">
                            <div className="num me-2">
                              <Select
                                isSearchable
                                options={supplier}
                                value={searchSupplier}
                                placeholder="Select Supplier"
                                onChange={(e) => {
                                  setSearchSupplier(e);
                                  //   GetAllSubCategory(e.value);
                                }}
                              />
                            </div>
                          </div>

                          {/* <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mt-md-0 mt-3">
                            <div className="num me-2 text-end">
                              <input
                                type="text"
                                className="form-control"
                                id=""
                                value={search}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </div>
                          </div> */}
                          <div className="col-xxl-12  col-12 mt-lg-0 mt-md-3 mt-3 text-end">
                            <div className="Search-1">
                              <button
                                type="button"
                                onClick={() => {
                                  setSearchSupplier("");
                                  setSearch("");
                                  setSearchCategory("");
                                  setSearchSubCategory("");
                                  setSearchChildSubCategory("");

                                  setResetData(!resetData);
                                }}
                                className="btn btn-reset"
                              >
                                Reset
                              </button>
                            </div>
                          </div>

                          {/* <div className="Search me-2">
                            <Button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </Button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-3">
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            {visible.col1 && <th className="sr">Sr. No.</th>}
                            {visible.col2 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Supplier
                              </th>
                            )}
                            {visible.col4 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Batch Number
                              </th>
                            )}
                            {visible.col5 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Products
                              </th>
                            )}{" "}
                            {visible.col7 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Varient
                              </th>
                            )}
                            {visible.col8 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Single Varient Name
                              </th>
                            )}
                            {visible.col9 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Multiple Varient Main Name
                              </th>
                            )}
                            {visible.col10 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Multiple Varient Sub Name
                              </th>
                            )}
                            {visible.col6 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Quantity
                              </th>
                            )}
                            {/* {visible.col7 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Manufacturer
                              </th>
                            )} */}
                            {/* {visible.col6 && (
                            <th className="tax-name">Password</th>
                          )} */}
                            {visible.col11 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Received Unit
                              </th>
                            )}
                            {visible.col8 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Status
                              </th>
                            )}
                            {visible.col9 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                {user?.id === 1 ? "Action " : "Action "}
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {/* {isAllow.includes(5) ? ( */}
                          {data?.data?.data?.map((d, index) => {
                            return (
                              <tr className="" key={index}>
                                {visible.col1 && <td>{index + 1}.</td>}
                                {visible.col2 && <td>{d?.user?.first_name}</td>}
                                {visible.col4 && <td>{d?.batch_no}</td>}
                                {visible.col5 && (
                                  <td>{d?.product?.name}</td>
                                )}{" "}
                                {visible.col7 && <td>{d?.type}</td>}{" "}
                                {visible.col8 && (
                                  <td>
                                    {d?.purchase_order_s_details?.map(
                                      (value, index) => (
                                        <div className="text-holder">
                                          <p className="mb-0" key={index}>
                                            {value?.product_variant?.v_name}
                                            <br />
                                          </p>
                                          <hr />
                                        </div>
                                      )
                                    )}
                                  </td>
                                )}
                                {visible.col9 && (
                                  <td>
                                    {d?.purchase_order_m_details?.map(
                                      (value, index) => (
                                        <div
                                          className="text-holder"
                                          key={index}
                                        >
                                          <p className="mb-0">
                                            {
                                              value?.product_multiple_main
                                                ?.varient_name
                                            }
                                          </p>
                                          <hr />
                                        </div>
                                      )
                                    )}
                                  </td>
                                )}
                                {visible.col10 && (
                                  <td>
                                    {/* {d?.purchase_order_m_details?.map(
                                      (value, index) => (
                                        <div key={index}>
                                          {value?.purchase_order_m_sub_details?.map(
                                            (data, subIndex) => (
                                              <div key={subIndex}>
                                                {
                                                  data?.product_multiple_variant
                                                    ?.varient_sub_name
                                                }
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )
                                    )} */}

                                    {d?.purchase_order_m_details?.map(
                                      (value, index) => (
                                        <div key={index}>
                                          {value?.purchase_order_m_sub_details?.map(
                                            (data, subIndex) => (
                                              <div
                                                className="text-holder"
                                                key={subIndex}
                                              >
                                                <p className="mb-0">
                                                  {
                                                    data
                                                      ?.product_multiple_variant
                                                      ?.varient_sub_name
                                                  }
                                                </p>
                                              </div>
                                            )
                                          )}{" "}
                                          <hr />
                                        </div>
                                      )
                                    )}
                                  </td>
                                )}
                                {visible.col6 && (
                                  <td>
                                    {d.type == "Single"
                                      ? d?.purchase_order_s_details?.map(
                                          (value, index) => (
                                            <div className="text-holder">
                                              <p className="mb-0" key={index}>
                                                {value?.unit} Units
                                                <br />
                                              </p>
                                              <hr />
                                            </div>
                                          )
                                        )
                                      : d?.purchase_order_m_details?.map(
                                          (value, index) => (
                                            <div key={index}>
                                              {value?.purchase_order_m_sub_details?.map(
                                                (data, subIndex) => (
                                                  <>
                                                    <div key={subIndex}>
                                                      {data?.unit} Units
                                                    </div>
                                                  </>
                                                )
                                              )}
                                              <hr />
                                            </div>
                                          )
                                        )}
                                  </td>
                                )}
                                {visible.col11 && (
                                  <td>
                                    {d.type === "Single"
                                      ? d?.purchase_receive_singles?.map(
                                          (data, index) => (
                                            <div key={index}>
                                              {data?.unit} <hr />
                                            </div>
                                          )
                                        )
                                      : d?.purchase_receive_multiple_mains?.map(
                                          (main, mainIndex) => (
                                            <div key={mainIndex}>
                                              {main?.purchase_receive_multiple_subs?.map(
                                                (sub, subIndex) => (
                                                  <div
                                                    key={`${mainIndex}-${subIndex}`}
                                                  >
                                                    {sub?.unit}
                                                  </div>
                                                )
                                              )}{" "}
                                              <hr />
                                            </div>
                                          )
                                        )}
                                  </td>
                                )}
                                {visible.col8 && (
                                  <td>
                                    {d.status == 0 ? (
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.status}
                                          disabled={!isAllow?.includes(249)}
                                          onClick={() => {
                                            GetEditData(d.id);
                                            handleShowStatus();
                                          }}
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.status
                                            ? "Stock Received"
                                            : "Stock Ordered"}
                                        </label>
                                      </div>
                                    ) : (
                                      "Stock received"
                                    )}
                                  </td>
                                )}
                                {visible.col9 && (
                                  <td>
                                    <div className="d-flex">
                                      {isAllow?.includes(251) ? (
                                        d.status === 0 && (
                                          <Button
                                            onClick={() => handleShow1(d?.id)}
                                            type="button"
                                            className="btn btn-primary me-1"
                                          >
                                            <img
                                              src={pen}
                                              className="pen"
                                              alt=""
                                            />
                                          </Button>
                                        )
                                      ) : (
                                        <></>
                                      )}
                                      {isAllow?.includes(252) ? (
                                        d.status === 0 && (
                                          <button
                                            onClick={() => {
                                              showDeleteRecord(d?.id, d?.name);
                                            }}
                                            type="button"
                                            className="btn btn-danger"
                                          >
                                            <img
                                              src={basket}
                                              className="pen"
                                              alt=""
                                            />
                                          </button>
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <Toaster position="top-right" />

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button className="btn btn-yes me-2" onClick={DeleteRecord}>
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* status modal start */}
      {/* <Modal
        className="status-modal"
        show={showStatus}
        onHide={handleCloseStatus}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-holder">
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Product</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter product Name"
                      disabled
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Quantity</Form.Label>
                    <Form.Control type="number" placeholder="Enter Quantity" />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseStatus}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCloseStatus}>
            Save
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal
        className="status-modal"
        show={showStatus}
        onHide={handleCloseStatus}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Type</Form.Label>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <Form.Control
                      type="number"
                      placeholder={receivedData?.type?.label}
                      required
                      disabled
                    />
                  </InputGroup>
                  {errors.message && (
                    <span className="text-danger">
                      {errors.message.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          <Col md={6}>
            <div className="main-form-section mt-3">
              <Row className="justify-content-center">
                <Form.Label>Product</Form.Label>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <Form.Control
                      type="number"
                      placeholder={receivedData?.product_id?.label}
                      required
                      disabled
                    />
                  </InputGroup>
                  {errors.message && (
                    <span className="text-danger">
                      {errors.message.message}
                    </span>
                  )}
                </Form.Group>
              </Row>
            </div>
          </Col>

          {receivedData?.type?.label === "Single" &&
            receivedData?.main_m_variants?.map((product, productIndex) => (
              <div key={productIndex} className="form-holder">
                <Form>
                  <div className="row">
                    <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Products</Form.Label>
                          <Controller
                            name={`product.${productIndex}.product_single_v_id`}
                            control={control}
                            defaultValue={{
                              value: product.product_single_v_id?.value,
                              label: product.product_single_v_id?.label,
                            }}
                            rules={{ required: "Select Supplier" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={productOption}
                                isDisabled={true}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.product?.[productIndex]
                                      ?.product_id
                                      ? "red"
                                      : baseStyles.borderColor,
                                  }),
                                }}
                              />
                            )}
                          />
                          {errors.product?.[productIndex]?.product_id && (
                            <span className="text-danger">
                              {errors.product[productIndex].product_id.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Quantity</Form.Label>
                          <Form.Group
                            className="mb-3"
                            controlId={`formQuantity${productIndex}`}
                          >
                            <InputGroup>
                              <Form.Control
                                type="number"
                                placeholder="Enter Quantity"
                                {...register(`product.${productIndex}.unit`, {
                                  required: "Stock Unit is required",
                                })}
                              />
                            </InputGroup>
                            {errors.product?.[productIndex]?.unit && (
                              <span className="text-danger">
                                {errors.product[productIndex].unit.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>
                  </div>
                </Form>
              </div>
            ))}

          {receivedData?.type?.label === "Multiple" &&
            receivedData?.main_m_variants?.map((product, productIndex) => (
              <div key={productIndex} className="form-holder">
                <Form>
                  <div className="row">
                    <Col md={6}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Products</Form.Label>
                          <Controller
                            name={`product[${productIndex}].product_m_main_id`}
                            control={control}
                            defaultValue={{
                              value: product.product_m_main_id?.value,
                              label: product.product_m_main_id?.label,
                            }}
                            rules={{ required: "Select Supplier" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={productOption}
                                isDisabled={true}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.product?.[productIndex]
                                      ?.product_id
                                      ? "red"
                                      : baseStyles.borderColor,
                                  }),
                                }}
                              />
                            )}
                          />
                          {errors.product?.[productIndex]?.product_id && (
                            <span className="text-danger">
                              {errors.product[productIndex].product_id.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>

                    <div className="row">
                      {product?.m_variants?.map((value, valueIndex) => (
                        <React.Fragment key={valueIndex}>
                          <Col md={6}>
                            <div className="main-form-section mt-3">
                              <Row className="row justify-content-center mb-2">
                                <Form.Label>Sub Variant</Form.Label>
                                <Controller
                                  name={`product.${productIndex}.m_sub_variants.${valueIndex}.product_m_sub_v_id`}
                                  control={control}
                                  defaultValue={{
                                    value: value.product_m_sub_v_id?.value,
                                    label: value.product_m_sub_v_id?.label,
                                  }}
                                  rules={{ required: "Select Supplier" }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      options={productOption}
                                      isDisabled={true}
                                      styles={{
                                        control: (baseStyles) => ({
                                          ...baseStyles,
                                          borderColor: errors.product?.[
                                            valueIndex
                                          ]?.product_id
                                            ? "red"
                                            : baseStyles.borderColor,
                                        }),
                                      }}
                                    />
                                  )}
                                />
                                {errors.product?.[valueIndex]?.product_id && (
                                  <span className="text-danger">
                                    {
                                      errors.product[valueIndex].product_id
                                        .message
                                    }
                                  </span>
                                )}
                              </Row>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="main-form-section mt-3">
                              <Row className="justify-content-center">
                                <Form.Label>Quantity</Form.Label>
                                <Form.Group
                                  className="mb-3"
                                  controlId={`formQuantity${valueIndex}`}
                                >
                                  <InputGroup>
                                    <Form.Control
                                      type="number"
                                      placeholder="Enter Quantity"
                                      required
                                      {...register(
                                        `product.${productIndex}.m_sub_variants.${valueIndex}.unit`,
                                        {
                                          required: "Stock Unit is required",
                                          min: {
                                            value: 0,
                                            message:
                                              "Quantity cannot be negative",
                                          },
                                        }
                                      )}
                                    />
                                  </InputGroup>
                                  {errors.product?.[valueIndex]?.unit && (
                                    <span className="text-danger">
                                      {errors.product[valueIndex].unit.message}
                                    </span>
                                  )}
                                </Form.Group>
                              </Row>
                            </div>
                          </Col>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </Form>
              </div>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseStatus}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit(ChangeStatusReceive)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Successfull_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message={"Order Recieved Successfully!"}
      />

      {/* status modal end */}
    </>
  );
};

export default Tables;
